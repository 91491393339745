import axios from "axios";
import utils from "@/services/utils";

export default {
  async search(searchOptions) {
    try {
      const response = await axios.get("/report/", {
        params: {
          ...utils.buildApiSearchQuery(searchOptions, "email", false),
          start_date: searchOptions.startDate,
          end_date: searchOptions.endDate,
          incident_nature: searchOptions.incidentNature.join(";"),
          species: searchOptions.species.join(";")
        },
      });
      const data = response.data;
      return { message: "Got reports", data: data.items, total: data.total };
    } catch (e) {
      return { message: "No reports found", data: [], total: 0 };
    }
  },
  async create(reportToCreate) {
    return await axios.post(`/report/`, reportToCreate);
  },
  async update(reportId, reportToUpdate) {
    return await axios.put(`/report/${reportId}`, reportToUpdate);
  },
  async exportCsv() {
    return window.open(
      axios.defaults.baseURL + `/report/whale_data_export/`
    );
  },
  async publicCreate(reportToCreate) {
    return await axios.post(`/report/public/`, reportToCreate);
  },
  async sendEmail(reportId, emailRequest) {
    return await axios.post(`/report/email/${reportId}`, emailRequest)
  },
  async previewEmail(reportId, emailRequest) {
    return await axios.post(`/report/email/preview/${reportId}`, emailRequest)
  }
};
