export default {
  buildApiSearchQuery(
    { query, page, itemsPerPage, sortBy, sortDesc },
    defaultSort = "id",
    defaultDesc = false
  ) {
    page = page ?? 1;
    itemsPerPage = itemsPerPage ?? -1;
    sortBy = sortBy && sortBy.length > 0 ? sortBy[0] : defaultSort;
    sortDesc =
      sortDesc != null && sortDesc.length > 0 ? sortDesc[0] : defaultDesc;
    query = query ?? "";

    const limit = itemsPerPage;
    const offset = (page - 1) * itemsPerPage;

    return {
      query: query,
      limit: limit,
      offset: offset,
      sort: sortBy,
      sort_desc: sortDesc,
    };
  },
};
