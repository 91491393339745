import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store";

import HomePage from "@/pages/HomePage.vue";
import Login from "@/pages/Login";
import Users from "@/pages/authenticated/Users";
import Reports from "@/pages/authenticated/Reports.vue";
import Dashboard from "@/pages/authenticated/Dashboard";
import PublicReportSubmission from "@/pages/PublicReportSubmission";
import PasswordReset from "@/pages/authenticated/PasswordReset.vue";
import ForgotPassword from "@/pages/ForgotPassword.vue";
import RegisterAccount from "@/pages/RegisterAccount.vue";
import AccountDelete from "@/pages/AccountDelete";
import AccountDeleteForm from "@/pages/AccountDeleteForm";
import PrivacyPolicy from "@/pages/PrivacyPolicy";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      name: "publicReportSubmission",
      path: "/public_report",
      component: PublicReportSubmission,
    },
    { name: "homePage", path: "/home", component: HomePage },
    { name: "login", path: "/login", component: Login },
    { name: "users", path: "/users", component: Users },
    { name: "reports", path: "/reports", component: Reports },
    { name: "dashboard", path: "/dashboard", component: Dashboard },
    { name: "reset", path: "/reset", component: PasswordReset },
    {
      name: "forgotPassword",
      path: "/forgotPassword",
      component: ForgotPassword,
    },
    { name: "register", path: "/register", component: RegisterAccount },
    { name: "accountDelete", path: "/account-management", component: AccountDelete },
    { name: "accountDeleteForm", path: "/account-management-form", component: AccountDeleteForm },
    { name: "privacyPolicy", path: "/privacy-policy", component: PrivacyPolicy },
  ],
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/reset",
    "/forgotPassword",
    "/public_report",
    "/register",
    "/home",
    "/account-management",
    "/account-management-form",
    "/privacy-policy",
  ];
  const authRequired = !publicPages.includes(to.path);
  const currentUser = store.getters["auth/isTokenSet"];
  const resetRequest = store.getters["auth/isPassTokenSet"];

  if (resetRequest) {
    return next("/reset");
  }

  if (authRequired && !currentUser) {
    return next("/home");
  }

  return next();
});

export default router;
