
const state = {
  dialog: false,
};

const getters = {
  dialog: (state) => state.dialog,
};

const actions = {
  open({ commit }) {
    commit("open");
  },
  close({ commit }) {
    commit("close");
  },
};

const mutations = {
  open(state) {
    state.dialog = true;
  },
  close(state) {
    state.dialog = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
