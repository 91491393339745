<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card class="mx-auto" :class="cardClass" align-self="center">
          <v-card-title justify="space-around" class="primary">
            <v-btn color="white" icon to="/home">
              <v-icon large>mdi-arrow-left</v-icon>
            </v-btn>
            <span
                class="text-h5 white--text pl-4"
                style="word-break: break-word"
            >
              Privacy Policy
            </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: auto">
            <div style="font-size: large" class="mt-6">
              <span>
                <b>Last updated March 12, 2024</b>
                <br/>
                <br/>
                <br/>
                <br/>
                This privacy policy for the North Coast Cetacean Society (NCCS, also known as BC Whales) (‘<b>we</b>’,
                ‘<b>us</b>’, or ‘<b>our</b>’), describes how and why we might collect, store, use, and/or share
                (‘<b>process</b>’) your information when you use our services (‘<b>Services</b>’), such as when you:
                <br/>
                <br/>
                <ul>
                  <ul style="list-style-type: disc">
                    <li>
                      Download and use our mobile application (Remote Report),
                      or any other application of ours that links to this
                      privacy policy
                    </li>
                    <br/>
                    <li>
                      Engage with us in other related ways, including any sales,
                      marketing, or events
                    </li>
                  </ul>
                </ul>
                <br/>
                <b>Questions of concerns?</b> Reading this privacy policy will help you understand your privacy rights
                and choices. If you do not agree with our policies and practices, please do not use our Services. If
                you still have any questions or concerns, please contact us at <a href="mailto:grace@bcwhales.org">grace@bcwhales.org</a>.
                <br/>
                <br/>
                <br/>
                <span class="text-h6">
                  SUMMARY OF KEY POINTS
                </span>
                <br/>
                <br/>
                <b><i>
                  This summary provides key points from our privacy policy, but you can find out more details about any
                  of these topics by clicking the link following each key point or by using
                  our <a href="#tableOfContents">table of contents</a> below to find the section you are looking for.
                </i></b>
                <br/>
                <br/>
                <b>What personal information do we process?</b> When you visit, use, or navigate our Services, we may
                process personal information depending on how you interact with us and the Services, the choices you
                make, and the products or features you use.
                Learn more about <a href="#section1">personal information you disclose to us</a>.
                <br/>
                <br/>
                <b>Do we process any sensitive personal information?</b> We do not process sensitive personal
                information.
                <br/>
                <br/>
                <b>Do we receive any information from third parties?</b> We do not receive any information from third
                parties.
                <br/>
                <br/>
                <b>How do we process your personal information?</b> We process your personal information to provide,
                improve, and administer our Services, communicate with you, and to comply with law. We may also process
                your information for other purposes with your consent. We process your information only when we have a
                valid legal reason to do so.
                Learn more about <a href="#section2">how we process your personal information</a>.
                <br/>
                <br/>
                <b>In what situations and with which types of parties do we share personal information?</b> We may
                share information in specific situations and with specific categories of third parties.
                Learn more about <a href="#section4">when and with whom we share your personal information</a>.
                <br/>
                <br/>
                <b>How do we keep your information safe?</b> We have organizational and technical processes and
                procedures in place to protect your personal information. However, no electronic transmission over the
                internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or
                guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat
                our security and improperly collect, access, steal, or modify your information.
                Learn more about <a href="#section6">how we keep your personal information safe and secure</a>.
                <br/>
                <br/>
                <b>What are your rights?</b> Depending on where you are located geographically, the applicable privacy
                law may mean you have certain rights regarding your personal information.
                Learn more about <a href="#section8">your privacy rights</a>.
                <br/>
                <br/>
                <b>How do you exercise your rights?</b> The easiest way to exercise your rights is by using the
                account settings within the application itself. Additional instructions for accessing account settings
                and managing your account can be found by visiting
                <a href="https://report.bcwhales.org/account-management">report.bcwhales.org/account-management</a>, or
                by contacting us. We will consider and act upon any request in accordance with applicable data
                protection laws.
                <br/>
                <br/>
                Want to learn more about what we do with any information we collect?
                <a href="#section1">Review the privacy policy in full</a>.
                <br/>
                <br/>
                <br/>
                <span class="text-h6" id="tableOfContents">
                  TABLE OF CONTENTS
                </span>
                <br/>
                <br/>
                <a href="#section1">1. WHAT INFORMATION DO WE COLLECT?</a><br/>
                <a href="#section2">2. HOW DO WE PROCESS YOUR INFORMATION?</a><br/>
                <a href="#section3">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</a><br/>
                <a href="#section4">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a><br/>
                <a href="#section5">5. HOW LONG DO WE KEEP YOU INFORMATION?</a><br/>
                <a href="#section6">6. HOW DO WE KEEP YOU INFORMATION SAFE?</a><br/>
                <a href="#section7">7. DO WE COLLECT INFORMATION FROM MINORS?</a><br/>
                <a href="#section8">8. WHAT ARE YOUR PRIVACY RIGHTS?</a><br/>
                <a href="#section9">9. CONTROLS FOR DO-NOT-TRACK FEATURES</a><br/>
                <a href="#section10">10. DO WE MAKE UPDATES TO THIS PRIVACY POLICY?</a><br/>
                <a href="#section11">11. HOW CAN YOU CONTACT US ABOUT THIS PRIVACY POLICY?</a><br/>
                <a href="#section12">12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a>
                <br/>
                <br/>
                <br/>
                <span class="text-h6" id="section1">
                  1. WHAT INFORMATION DO WE COLLECT?
                </span>
                <br/>
                <br/>
                <b>Personal information you disclose to us:</b>
                <br/>
                <br/>
                <i>
                  <b>In Short:</b> We collect personal information that you provide to us.
                </i>
                <br/>
                <br/>
                We collect personal information that you voluntarily provide to us when you register on the Services,
                express an interest in obtaining information about us or our products and Services, when you
                participate in activities on the Services, or otherwise when you contact us.
                <br/>
                <br/>
                <b>Personal Information Provided by You:</b> The personal information that we collect depends on the
                context of your interactions with us and the Services, the choices you make, and the products and
                features you use. The personal information we collect may include the following:
                <br/>
                <br/>
                <ul>
                  <ul style="list-style-type: disc">
                    <li>
                      Names
                    </li>
                <br/>
                    <li>
                      Phone numbers
                    </li>
                <br/>
                    <li>
                      Email addresses
                    </li>
                <br/>
                    <li>
                      Usernames and Passwords used for the app
                    </li>
                  </ul>
                </ul>
                <br/>
                <b>Sensitive Information:</b> We do not process sensitive personal information.
                <br/>
                <br/>
                <b>Application Data:</b> If you use our application(s), we also may collect the following information
                if you choose to provide us with access or permission:
                <br/>
                <br/>
                <ul>
                  <ul style="list-style-type: disc">
                    <li>
                      Geolocation Information: We may request access or permission to use location-based information
                      from your mobile device, on a by-request basis while using our mobile application(s), to provide
                      certain location-based services. If you wish to change our access or permissions, you may do so
                      in your device’s settings.
                    </li>
                  </ul>
                </ul>
                <br/>
                This information is primarily needed to maintain the operation of our application(s) when submitting
                reports.
                <br/>
                <br/>
                All personal information that you provide to us must be true, complete, and accurate, and you must
                notify us of any changes to such personal information.
                <br/>
                <br/>
                <br/>
                <span class="text-h6" id="section2">
                  2. HOW DO WE PROCESS YOUR INFORMATION?
                </span>
                <br/>
                <br/>
                <i>
                  <b>In Short:</b> We process your personal information to provide, improve, and administer our
                  Services, communicate with you, and to comply with laws and regulations. We may also process your
                  information for other purposes with your consent.
                </i>
                <br/>
                <br/>
                <b>We process your personal information for a variety of reasons, depending on how you interact with
                  our Services, including:
                </b>
                <br/>
                <br/>
                <ul>
                  <ul style="list-style-type: disc">
                    <li>
                      <b>To facilitate account creation and authentication and otherwise manage user accounts:</b> We
                      may process your information so you can create and log in to your account, as well as keep your
                      account in working order.
                    </li>
                <br/>
                    <li>
                      <b>User account management:</b> Information provided by the user will be used to conduct basic
                      user account management activities such as creating, updating, and deleting.
                    </li>
                <br/>
                    <li>
                      <b>Report submissions:</b> Information provided by the user may be used to create or update
                      marine mammal incident reports which will be stored internally for review and reference.
                    </li>
                <br/>
                    <li>
                      <b>Communication of reports:</b> The reports created using the information provided by the user
                      may, where applicable, be sent to appropriate third parties for the purpose of resolving the
                      incident being reported and/or notifying the aforementioned third parties to be vigilant for the
                      marine mammal described in the report so appropriate action can be taken in a timely manner.
                    </li>
                  </ul>
                </ul>
                <br/>
                <br/>
                <span class="text-h6" id="section3">
                  3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
                </span>
                <br/>
                <br/>
                <i>
                  <b>In Short:</b> We only process your personal information when we believe it is necessary and we
                  have a valid legal reason (i.e. legal basis) to do so under applicable law. These include using it in
                  manners for which your consent has been explicitly or implicitly provided, to comply with laws, to
                  provide you with services to enter into or fulfill our contractual obligations, to protect your
                  rights, or to fulfill our legitimate organizational interests.
                </i>
                <br/>
                <br/>
                We may process your information if you have given us specific permission (i.e. express consent) to use
                your personal information for a specific purpose, or in situations where your permission can be
                inferred (i.e. implied consent). You can <a href="#withdrawConsent">withdraw your consent</a> at any
                time.
                <br/>
                <br/>
                In some exceptional cases, we may be legally permitted under applicable law to process your information
                without your consent, including, for example:
                <br/>
                <br/>
                <ul>
                  <ul style="list-style-type: disc">
                    <li>
                      If collection is clearly in the interests of an individual and consent cannot be obtained in a
                      timely way
                    </li>
                <br/>
                    <li>
                      For investigations and fraud prevention and detection
                    </li>
                <br/>
                    <li>
                      If it is contained in a witness statement and the collection is necessary to assess, process, or
                      settle an insurance claim
                    </li>
                <br/>
                    <li>
                      For identifying injured, ill, or deceased persons and communicating with next of kin
                    </li>
                <br/>
                    <li>
                      If we have reasonable grounds to believe an individual has been, is, or may be a victim of
                      financial abuse
                    </li>
                <br/>
                    <li>
                      If it is reasonable to expect collection and use with consent would compromise the availability
                      or the accuracy of the information and the collection is reasonable for purposes related to
                      investigating a breach of an agreement or a contravention of the laws of Canada or a province
                    </li>
                <br/>
                    <li>
                      If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court
                      relating to the production of records
                    </li>
                <br/>
                    <li>
                      If it was produced by an individual in the course of their employment, business, or profession
                      and the collection is consistent with the purposes for which the information was produced
                    </li>
                <br/>
                    <li>
                      If the collection is solely for journalistic, artistic, or literary purposes
                    </li>
                <br/>
                    <li>
                      If the information is publicly available and is specified by applicable regulations
                    </li>
                  </ul>
                </ul>
                <br/>
                <br/>
                <span class="text-h6" id="section4">
                  4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </span>
                <br/>
                <br/>
                <i>
                  <b>In Short:</b> We may share information in specific situations described in this section and/or
                  with the following categories of third parties.
                </i>
                <br/>
                <br/>
                <b>Third Party Agents:</b> We may share your data with third-party agents (‘<b>third parties</b>’) who
                perform services for us or on our behalf and require access to such information to do that work. The
                categories of third parties we may share personal information with are as follows:
                <br/>
                <br/>
                <ul>
                  <ul style="list-style-type: disc">
                    <li>
                      Government entities
                    </li>
                <br/>
                    <li>
                      Relevant partners for the monitoring and resolution of submitted reports
                    </li>
                  </ul>
                </ul>
                <br/>
                We also may need to share your personal information in the following circumstances:
                <br/>
                <br/>
                <ul>
                  <ul style="list-style-type: disc">
                    <li>
                      <b>Regulatory compliance:</b> We may share or transfer your information in circumstances where it
                      is required in order to comply with applicable laws and regulations.
                    </li>
                  </ul>
                </ul>
                <br/>
                <br/>
                <span class="text-h6" id="section5">
                  5. HOW LONG DO WE KEEP YOUR INFORMATION?
                </span>
                <br/>
                <br/>
                <i>
                  <b>In Short:</b> We keep your information for as long as is necessary to fulfill the purposes
                  outlined in this privacy policy unless otherwise required by law.
                </i>
                <br/>
                <br/>
                We will only keep your personal information for as long as it is necessary for the purposes set out in
                this privacy policy, unless a longer retention period is required or permitted by law (such as tax,
                accounting, or other legal requirements). No purpose in this privacy policy will require us keeping
                your personal information for longer than the period of time in which users have an account with us,
                with the exception of cases in which the retention of this information is required for continued proper
                application functionality such as persisting in archives of submitted reports.
                <br/>
                <br/>
                When we have no ongoing legitimate organizational need to process your personal information, we will
                either delete or anonymize such information, or, if this is not possible (for example: because your
                personal information has been stored in backup archives), then we will securely store your personal
                information and isolate it from any further processing until deletion is possible.
                <br/>
                <br/>
                <br/>
                <span class="text-h6" id="section6">
                  6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </span>
                <br/>
                <br/>
                <i>
                  <b>In Short:</b> We aim to protect your personal information through a system of organizational and
                  technical security measures.
                </i>
                <br/>
                <br/>
                We have implemented appropriate and reasonable technical and organizational security measures designed
                to protect the security of any personal information we process. However, despite our safeguards and
                efforts to secure your personal information, no electronic transmission over the internet or
                information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee
                that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our
                security and improperly collect, access, steal, or modify your information. Although we will do our
                best to protect your personal information, transmission of personal information to and from our
                Services is at your own risk. You should only access the Services within a secure environment.
                <br/>
                <br/>
                <br/>
                <span class="text-h6" id="section7">
                  7. DO WE COLLECT INFORMATION FROM MINORS?
                </span>
                <br/>
                <br/>
                <i>
                  <b>In Short:</b> We do not knowingly collect data from or market to children under the age of 18
                  years of age.
                </i>
                <br/>
                <br/>
                We do not knowingly solicit data from or market to children under 18 years of age. By using the
                Services, you represent that you are at least 18 years of age or that you are the parent or guardian of
                such a minor and consent to the aforementioned minor dependent’s use of the Services. If we learn that
                personal information from users less than 18 years of age has been collected, we will deactivate the
                associated account and take reasonable measures to promptly delete such data from our records. If you
                become aware of any data we may have collected from children under age 18 that falls outside the above
                provisions, please contact us at <a href="mailto:grace@bcwhales.org">grace@bcwhales.org</a>.
                <br/>
                <br/>
                <br/>
                <span class="text-h6" id="section8">
                  8. WHAT ARE YOUR PRIVACY RIGHTS?
                </span>
                <br/>
                <br/>
                <i>
                  <b>In Short:</b> In some regions, such as Canada, you have rights that allow you greater access to
                  and control over your personal information. You may review, change, or terminate your account at any
                  time.
                </i>
                <br/>
                <br/>
                In some regions (like Canada), you have certain rights under applicable data protection laws. These may
                include the right:
                <br/>
                <br/>
                <ul>
                  <ul style="list-style-type: disc">
                    <li>
                      (i) to request access and obtain a copy of your personal information
                    </li>
                <br/>
                    <li>
                      (ii) to request rectification or erasure
                    </li>
                <br/>
                    <li>
                      (iii) to restrict the processing of your personal information
                    </li>
                <br/>
                    <li>
                      (iv) if applicable, to data portability
                    </li>
                <br/>
                    <li>
                      (v) not to be subject to automated decision making
                    </li>
                  </ul>
                </ul>
                <br/>
                In certain circumstances, you may also have the right to object to the processing of your personal
                information. You can make a request by contacting us by using the contact details provided in the
                section ‘<a href="#section11">How can you contact us about this privacy policy?</a>’ below.
                <br/>
                <br/>
                We will consider and act upon any request in accordance with applicable data protection laws.
                <br/>
                <br/>
                <span id="withdrawConsent"><u><b>Withdrawing your consent</b></u></span>: If we are relying on your
                consent to process personal information, which may be express and/or implied consent depending on the
                applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent
                at any time by contacting us using the contact details provided in the
                section ‘<a href="#section11">How can you contact us about this privacy policy?</a>’ below.
                <br/>
                <br/>
                However, please note that this will not affect the lawfulness of the processing before its withdrawal
                nor, when applicable law allows, will it affect the processing of your personal information conducted
                in reliance on lawful processing grounds other than consent.
                <br/>
                <br/>
                <b>Account Information</b>
                <br/>
                <br/>
                If you would at any time like to review or change the information in your account or terminate your
                account, you can:
                <br/>
                <br/>
                <ul>
                  <ul style="list-style-type: disc">
                    <li>
                      Log in to your account settings and update your user account
                    </li>
                <br/>
                    <li>
                      Contact us using the contact information provided
                    </li>
                  </ul>
                </ul>
                <br/>
                Upon your request to terminate your account, we will deactivate or delete your account and information
                from our active databases. However, we may retain some information in our files to prevent fraud,
                troubleshoot problems, assist with any investigations, maintain application functionality, enforce
                our legal terms, and/or comply with applicable legal requirements.
                <br/>
                <br/>
                If you have any questions or comments about your privacy rights, you may email us
                at <a href="mailto:grace@bcwhales.org">grace@bcwhales.org</a>.
                <br/>
                <br/>
                <br/>
                <span class="text-h6" id="section9">
                  9. CONTROLS FOR DO-NOT-TRACK FEATURES
                </span>
                <br/>
                <br/>
                <i>
                  <b>In Short:</b> While we do not implement nor respond to any DNT features, we also do not monitor
                  or collect any information related to online browsing activities.
                </i>
                <br/>
                <br/>
                Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
                (DNT) feature or setting you can activate to signal your privacy preferences not to have data about
                your online browsing activities monitored or collected. At this stage, no uniform technology standard
                for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond
                to DNT browser signals or any other mechanism that automatically communicates your choice not to be
                tracked online. If a standard for online tracking is adopted that we must follow in the future, we
                will inform you about that practice in a revised version of this privacy policy.
                <br/>
                <br/>
                With the above being stated, the application(s) covered by this privacy policy do not at present
                monitor nor collect any information related to your online browsing habits or activities.
                <br/>
                <br/>
                <br/>
                <span class="text-h6" id="section10">
                  10. DO WE MAKE UPDATES TO THIS PRIVACY POLICY?
                </span>
                <br/>
                <br/>
                <i>
                  <b>In Short:</b> Yes, we will update this privacy policy as necessary to stay compliant with
                  relevant laws.
                </i>
                <br/>
                <br/>
                We may update this privacy policy from time to time. The updated version will be indicated by an
                updated ‘Last Revised’ date and the updated version will be effective as soon as it is accessible. If
                we make material changes to this privacy policy, we may notify you either by prominently posting a
                notice of such changes or by directly sending you a notification. We encourage you to review this
                privacy policy frequently to be informed of how we are protecting your information.
                <br/>
                <br/>
                <br/>
                <span class="text-h6" id="section11">
                  11. HOW CAN YOU CONTACT US ABOUT THIS PRIVACY POLICY?
                </span>
                <br/>
                <br/>
                If you have questions or comments about this notice, you may email us
                at <a href="mailto:grace@bcwhales.org">grace@bcwhales.org</a> or contact us by post at:
                <br/>
                <br/>
                North Coast Cetacean Society<br/>
                26 Cottonwood Rd<br/>
                Alert Bay, British Columbia&nbsp;&nbsp;&nbsp;V0N 1A0<br/>
                Canada
                <br/>
                <br/>
                <br/>
                <span class="text-h6" id="section12">
                  12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                </span>
                <br/>
                <br/>
                Based on the applicable laws of your country, you may have the right to request access to the personal
                information we collect from you, change that information, or delete it. In order to accomplish this,
                you can log in to your account settings within the application which will allow you to view, update,
                or delete your account. To request further changes or if the above is not possible for any reason,
                please email us at <a href="mailto:grace@bcwhales.org">grace@bcwhales.org</a>. Additionally,
                instructions for how to manage your account can be found
                at <a href="https://report.bcwhales.org/account-management">report.bcwhales.org/account-management</a>.
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  computed: {
    isMobile() {
      const breakpoint = this.$vuetify.breakpoint.name;
      return breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md";
    },
    cardClass() {
      if (this.isMobile) {
        return "cardClassMobile"
      }
      else {
        return "cardClassDesktop"
      }
    }
  },
};
</script>

<style scoped>
.cardClassDesktop {
  max-width: 50vw;
}
.cardClassMobile {
  max-width: 90vw;
}
.cardImages {
  display: flex;
  justify-content: center;
}
</style>
