<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <form @submit.prevent="submitEmail">
          <v-card class="mx-auto" align-self="center" max-width="500">
            <v-card-title justify="space-around" class="primary">
              <v-btn color="white" icon to="/login">
                <v-icon large>mdi-arrow-left</v-icon> </v-btn
              ><span
                class="text-h5 white--text pl-4"
                style="word-break: break-word"
            >
                Forgot Password?
              </span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 200px">
              <div
                v-if="!saved"
                style="font-size: medium"
                class="mt-6">
                <span>Please enter your registered email address. We will send you a link to reset your password.</span>
              </div>
              <div>
                <div v-if="!saved">
                  <v-text-field
                    label="Email"
                    v-model="email"
                    prepend-icon="mdi-email"
                    :rules="emailRules"
                    type="email"
                  ></v-text-field>
                </div>
                <v-row style="justify-content: center">
                  {{ this.message }}
                </v-row>
              </div>
              <div v-if="saved" class="mt-12" style="font-size: medium">
                If {{ this.email }} is registered, a link to reset your password
                was sent to your email.
              </div>
            </v-card-text>
            <v-card-actions>
              <v-row class="mb-4 mr-2" justify="end">
                <v-btn
                  class="ml-2"
                  v-if="!saved"
                  color="primary"
                  type="submitEmail">
                  <span>Send Email</span>
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      message: "",
      email: "",
      emailRules: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      saved: false,
      canSave: false,
      loginPath: "/login",
    };
  },
  async created() {
    this.message = "";
  },
  computed: {
    ...mapGetters({
      user: "reset/user",
    }),
  },
  methods: {
    ...mapActions({
      sendPasswordEmail: "reset/sendPasswordEmail",
    }),
    async submitEmail() {
      try {
        await this.sendPasswordEmail({
          email: this.email,
        });
        this.saved = true;
      } catch (e) {
        console.log(e);
        this.saved = false;
      }
    },
  },
};
</script>
