import reportApi from "@/services/api/reports";
import utils from "@/helpers/utils";
import enums from "@/helpers/enums";

function formatReportForApi(report) {
  report.sightingTime = utils.toIsoDateString(report.sightingTime);
  report.reportDate = utils.toIsoDateString(report.reportDate);
  return report;
}

const state = {
  report: {},
  isSaving: false,
  isSavingEmail: false,
  isSaved: false,
  isDialogOpen: false,
  isListed: false,
  emailPreview: {},
  specOtherValue: "",
  incOtherValue: "",
  locOtherValue: "",
};

const getters = {
  saving: (state) => state.isSaving,
  emailSaving: (state) => state.isSavingEmail,
  saved: (state) => state.isSaved,
  report: (state) => state.report,
  dialog: (state) => state.isDialogOpen,
  button: (state) => (state.report && state.report.id ? "Save" : "Create"),
  title: (state) =>
    state.report && state.report.id
      ? `Edit Report ${state.report.reportNumber}`
      : "Create New Report",
  emailPreview: (state) => state.emailPreview,
  specOtherValue: (state) => state.specOtherValue,
  incOtherValue: (state) => state.incOtherValue,
  locOtherValue: (state) => state.locOtherValue,
};

const actions = {
  open({ commit }, { report }) {
    commit("open", { report });
    commit("handleOptionalTextboxes", { report });
  },
  close({ commit }) {
    commit("close");
  },
  async update({ commit, dispatch }, { report }) {
    commit("saving", { isSaving: true });

    const reportResult = await reportApi.update(
      report.id,
      formatReportForApi(report)
    );

    if (reportResult) {
      dispatch("reports/reload", {}, { root: true });
      commit("saveComplete", { isSaveComplete: true });
    } else {
      commit("saveComplete", { isSaveComplete: false });
    }
  },
  async create({ commit, dispatch }, { report }) {
    commit("saving", { isSaving: true });
    report.reportNumber = 1; //temporarily set for api call, will be overwritten in the backend with the correct value
    const reportResult = await reportApi.create(formatReportForApi(report));

    if (reportResult) {
      dispatch("reports/reload", {}, { root: true });
      commit("saveComplete", { isSaveComplete: true });
    } else {
      commit("saveComplete", { isSaveComplete: false });
    }
  },
  resetSaving({ commit }) {
    commit("saving", { isSaving: false })
  },
  async publicCreate({ commit, dispatch }, { report }) {
    commit("saving", { isSaving: true });
    report.reportNumber = 1; //temporarily set for api call, will be overwritten in the backend with the correct value
    const reportResult = await reportApi.publicCreate(formatReportForApi(report));

    if (reportResult) {
      dispatch("reports/reload", {}, { root: true });
      commit("saveComplete", { isSaveComplete: true });
    } else {
      commit("saveComplete", { isSaveComplete: false });
    }
  },
  async sendEmail({ commit }, { reportId, emailRequest }) {
    commit("emailSaving", { isSaving: true })
    await reportApi.sendEmail(reportId, emailRequest);
    commit("emailSaving", { isSaving: false });
  },
  async previewEmail({ commit }, { reportId, emailRequest }) {
    commit("emailSaving", { isSaving: true })
    const previewBody = await reportApi.previewEmail(reportId, emailRequest);
    commit("emailPreview", { emailPreview: previewBody.data.previewBody })
    commit("emailSaving", { isSaving: false });
  }
};

const mutations = {
  open(state, { report }) {
    state.report = { ...report };
    state.isDialogOpen = true;
  },
  close(state) {
    state.isDialogOpen = false;
    state.report = {};
    state.specOtherValue = "";
    state.incOtherValue = "";
    state.locOtherValue = "";
  },
  saving(state, { isSaving }) {
    state.isSaving = isSaving;
  },
  emailSaving(state, { isSaving }) {
    state.isSavingEmail = isSaving;
  },
  emailPreview(state, { emailPreview }) {
    state.emailPreview = emailPreview;
  },
  saveComplete(state, { isSaveComplete }) {
    if (isSaveComplete) {
      state.isSaved = true;
      state.isDialogOpen = false;
      state.report = {};
    }
    state.isSaving = false;
  },
  handleOptionalTextboxes(state, { report }) {
    Object.values(enums.species).forEach((value) => {
      if (report.species === value.toString()) {
        state.isListed = true;
      }
    });
    if (!state.isListed) {
      state.specOtherValue = report.species;
      state.report.species = "Other";
    }
    state.isListed = false;

    Object.values(enums.incidentType).forEach((value) => {
      if (report.incidentNature === value.toString()) {
        state.isListed = true;
      }
    });
    if (!state.isListed) {
      state.incOtherValue = report.incidentNature;
      state.report.incidentNature = "Other";
    }
    state.isListed = false;

    Object.values(enums.injuryLocation).forEach((value) => {
      if (report.injuryLocation === value.toString()) {
        state.isListed = true;
      }
    });
    if (!state.isListed) {
      state.locOtherValue = report.injuryLocation;
      state.report.injuryLocation = "Other";
    }
    state.isListed = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
