<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <form @submit.prevent="submit">
          <v-card class="mx-auto" align-self="center" max-width="650">
            <v-card-title justify="space-around" class="primary">
              <v-btn color="white" icon to="/home">
                <v-icon large>mdi-arrow-left</v-icon> </v-btn
              ><span
                class="text-h5 white--text pl-4"
                style="word-break: break-word"
              >
                Login - BCWhales Portal
              </span>
              <v-spacer />
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-alert v-if="message.text" type="error">
                {{ message.text }}
              </v-alert>
              <v-text-field
                label="Email"
                v-model="email"
                prepend-icon="mdi-account"
              ></v-text-field>
              <v-text-field
                label="Password"
                v-model="password"
                prepend-icon="mdi-lock"
                type="password"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn text link :to="this.forgotPasswordPath">
                Forgot Password?
              </v-btn>
              <v-spacer />
              <v-btn class="ml-2" color="primary" type="submit">
                <span v-show="!loading">Login</span>
                <pulse-loader :loading="loading"></pulse-loader>
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
// import HumpbackLogo from "@/components/svgs/HumpbackLogo";

export default {
  components: {
    PulseLoader,
    // HumpbackLogo,
  },

  data() {
    return {
      email: "",
      password: "",
      forgotPasswordPath: "/forgotPassword",
    };
  },
  created() {
    this.reset();
    if (this.isTokenSet) {
      if (this.role === "admin") {
        this.$router.push({ name: "dashboard" });
      }
      this.$router.push({ name: "reports" });
    }
  },
  computed: {
    ...mapGetters({
      loading: "auth/loading",
      isTokenSet: "auth/isTokenSet",
      message: "auth/message",
      role: "auth/role",
    }),
  },
  methods: {
    ...mapActions({
      reset: "auth/reset",
      login: "auth/login",
    }),
    async submit() {
      await this.login({
        email: this.email,
        password: this.password,
      });
      this.password = null;
    },
  },
};
</script>
