<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <h4 class="text-h4">
          <v-icon x-large class="pb-2">mdi-calendar-check</v-icon>
          Reports
        </h4>
      </v-col>
      <v-col class="text-right">
        <v-btn color="primary" class="mt-5" @click="createReport">
          Create Report
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pr-6">
        <v-menu
          v-model="dateField"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
          dense
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Date Filter"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
              @click:clear="date = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            range
            show-adjacent-months
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-text-field
          v-model="query"
          prepend-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
            :items="enums.incidentType"
            v-model="selectedIncidentNatures"
            prepend-icon="mdi-alert-circle"
            label="Incident Nature"
            name="incident_nature"
            multiple
            clearable
            @keyup="executeSearch"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index < maxSelectDisplay">{{ item }} &nbsp;</span>
            <span
                v-if="index === maxSelectDisplay"
                class="grey--text caption"
            >(+{{ selectedIncidentNatures.length - maxSelectDisplay }} more)</span>
          </template>
        </v-select>
      </v-col>
      <v-col>
        <v-select
            :items="enums.species"
            v-model="selectedSpecies"
            label="Species"
            name="species"
            multiple
            clearable
            @keyup="executeSearch"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index < maxSelectDisplay">{{ item }} &nbsp;</span>
            <span
                v-if="index === maxSelectDisplay"
                class="grey--text caption"
            >(+{{ selectedSpecies.length - maxSelectDisplay }} more)</span>
          </template>
          <template v-slot:prepend>
            <humpback-icon></humpback-icon>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-sheet elevation="2" class="pa-md-2">
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :options.sync="options"
            :server-items-length="total">
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                      small
                      class="mr-2"
                      v-on="on"
                      :disabled="reportDialog"
                      @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                </template>
                <span>View Report</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-sheet>
        <v-btn color="primary" class="mt-5" @click="exportData">
          Export as CSV
        </v-btn>
      </v-col>
    </v-row>
    <report-details></report-details>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "lodash";
import ReportDetails from "@/components/report/Details";
import enums from "@/helpers/enums";
import HumpbackIcon from "@/components/svgs/HumpbackIcon";

export default {
  components: {
    ReportDetails,
    HumpbackIcon,
  },
  data() {
    return {
      maxSelectDisplay: 1,
      visible: true,
      query: "",
      date: null,
      dateField: false,
      selectedIncidentNatures: [],
      selectedSpecies: [],
      options: {},
      headers: [
        { text: "Name", sortable: true, value: "fullName" },
        { text: "Report Number", sortable: true, value: "reportNumber" },
        { text: "Email", sortable: true, value: "email" },
        {
          text: "Date/Time of Incident",
          sortable: true,
          value: "sightingTime",
        },
        { text: "Report Date", sortable: true, value: "reportDate" },
        { text: "Species Involved", sortable: true, value: "species" },
        {
          text: "Actions",
          sortable: false,
          value: "actions",
          align: "center",
          width: "10%",
        },
      ],
    };
  },
  async mounted() {
    await this.reload();
  },
  created() {
    this.options = this.searchOptions;
    this.query = this.searchQuery;
    if (this.searchDate != null) this.date = this.searchDate;
    if (this.searchIncidentNature != null) {
      this.selectedIncidentNatures = this.searchIncidentNature;
    }
    if (this.searchSpecies != null) {
      this.selectedSpecies = this.searchSpecies;
    }
    this.debouncedSearch = debounce(this.executeSearch, 250);
    this.close()
  },
  computed: {
    ...mapGetters({
      loading: "reports/loading",
      items: "reports/items",
      searchOptions: "reports/searchOptions",
      searchQuery: "reports/searchQuery",
      searchDate: "reports/searchDate",
      total: "reports/total",
      reportDialog: "report/details/dialog",
    }),
    dateRangeText () {
      if (this.date !== null) {
        if (this.date.length > 1) {
          return this.date.join(" ~ ");
        }
        else {
          return this.date;
        }
      }
      else {
        return this.date;
      }
    },
    enums() {
      return enums;
    },
  },
  watch: {
    query() {
      if (this.query == null) this.query = "";
      this.debouncedSearch();
      this.options.page = 1;
    },
    date() {
      this.debouncedSearch();
      this.options.page = 1;
    },
    async selectedIncidentNatures() {
      await this.executeSearch()
    },
    async selectedSpecies() {
      await this.executeSearch()
    },
    options() {
      this.executeSearch();
    },
  },
  methods: {
    ...mapActions({
      search: "reports/search",
      open: "report/details/open",
      reload: "reports/reload",
      close: "report/details/close",
      exportCsv: "reports/exportCsv",
    }),
    async executeSearch() {
      await this.search({
        ...this.options,
        query: this.query,
        date: this.date,
        incidentNature: this.selectedIncidentNatures,
        species: this.selectedSpecies,
      });
    },
    editItem(item) {
      this.open({ report: item });
    },
    async createReport() {
      await this.open({ report: null });
    },
    async exportData() {
      await this.exportCsv()
    }
  },
};
</script>
