<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <form @submit.prevent="submitEmail">
          <v-card class="mx-auto" align-self="center" max-width="700">
            <v-card-title justify="space-around" class="primary">
              <v-btn color="white" icon to="/account-management">
                <v-icon large>mdi-arrow-left</v-icon>
              </v-btn>
              <span
                  class="text-h5 white--text pl-4"
                  style="word-break: break-word"
              >
                Account Management Form
              </span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-alert v-if="responseMessage" type="error">
                {{ responseMessage }}
              </v-alert>
              <v-form ref="form" v-model="valid" lazy-validation v-if="!saved">
                <div
                    v-if="!saved"
                    style="font-size: medium"
                    class="mt-6">
                  <span>Please fill out the form below and we will handle your account management needs. </span>
                </div>
                <div v-if="!saved">
                  <v-text-field
                      label="Full Name"
                      v-model="name"
                      prepend-icon="mdi-account"
                      type="text"
                      :rules="rules"
                  ></v-text-field>
                </div>
                <div>
                  <div v-if="!saved">
                    <v-text-field
                        label="Email"
                        v-model="email"
                        prepend-icon="mdi-email"
                        :rules="emailRules"
                        type="email"
                    ></v-text-field>
                  </div>
                  <v-row style="justify-content: center">
                    {{ this.message }}
                  </v-row>
                </div>
                <div v-if="!saved">
                  <v-textarea
                      name="email_message"
                      label="Message"
                      outlined
                      v-model="emailMessage"
                      :rules="rules"
                      class="pt-12"
                  ></v-textarea>
                </div>
              </v-form>
              <div v-if="saved && !responseMessage" class="mt-12" style="font-size: medium">
                Your email has been sent.
              </div>
            </v-card-text>
            <v-card-actions>
              <v-row class="mb-4 mr-2" justify="end">
                <v-btn
                    class="ml-2"
                    v-if="!saved"
                    color="primary"
                    type="submitEmail">
                  <span>Send Email</span>
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      message: "",
      email: "",
      emailRules: [
        (v) =>
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "E-mail must be valid",
      ],
      name: "",
      emailMessage: "",
      rules: [(v) => !!v || "This field is required!"],
      saved: false,
      canSave: false,
      valid: true,
    };
  },
  async created() {
    this.message = "";
    await this.setResponseMessage("")
  },
  computed: {
    ...mapGetters({
      user: "reset/user",
      responseMessage: "reset/responseMessage",
    }),
  },
  methods: {
    ...mapActions({
      accountManagementEmail: "reset/accountManagementEmail",
      setResponseMessage: "reset/setResponseMessage",
    }),
    async submitEmail() {
      try {
        await this.$refs.form.validate();
        if (!this.valid) {
          return;
        }
        await this.accountManagementEmail({
          email: this.email,
          name: this.name,
          message: this.emailMessage
        });
        this.saved = !this.responseMessage;
      } catch (e) {
        console.log(e);
        this.saved = false;
      }
    },
  },
};
</script>
