import axios from "axios";
import utils from "@/services/utils";

export default {
  async search(searchOptions) {
    try {
      const response = await axios.get("/user/", {
        params: utils.buildApiSearchQuery(searchOptions, "email", false),
      });
      const data = response.data;
      return { message: "Got users", data: data.items, total: data.total };
    } catch (e) {
      return { message: "No user found", data: [], total: 0 };
    }
  },
  async reset(token, password) {
    const body = { password: password };
    return await axios.put(`/user/password?token=${token}`, body);
  },
  async passwordEmail(email) {
    const body = { email: email};
    return await axios.post(`user/password`, body);
  },
  async accountManagementEmail(email, name, message) {
    const body = { email: email, name: name, message: message};
    return await axios.post(`user/account_management_email`, body);
  },
  async create(userToCreate) {
    return await axios.post(`/user/`, userToCreate);
  },
  async update(userId, patientToUpdate) {
    return await axios.put(`/user/${userId}`, patientToUpdate);
  },
  async register(userToRegister) {
    return await axios.post(`/user/register`, userToRegister);
  },
  async loadRoles() {
    return await axios.get(`/user/role`);
  },
  async delete(userId) {
    return await axios.delete(`/user/${userId}`);
  },
  async getCurrentUser() {
    return await axios.get(`/user/me`);
  },
};
