export default {
  formatIsoDate(isoDate) {
    if (!isoDate) {
      return "";
    }
    return isoDate.split("T")[0];
  },
  formatIsoDateTime(isoDate) {
    if (!isoDate) {
      return "";
    }
    const split = isoDate.split("T");
    // In case there is a Z on the end, don't include it. Use the first 5 digits
    return split[0] + " " + split[1].split("Z")[0].slice(0, 5);
  },
  toIsoDateString(dateString) {
    if (!dateString) {
      // Case 1 - string is null or empty, return null
      return null;
    }
    if (dateString.includes("T")) {
      // Case 2 - "2021-01-01T00:00:00" - AKA it is already how we want
      return dateString;
    }
    if (dateString.includes(" ")) {
      // Case 3 - "2021-01-01 00:00:00" - We have a date and a time, and we need that T in the middle
      return dateString.replace(" ", "T");
    }
    // Case 4 - "2021-01-01" - Append the time part
    return dateString + "T00:00:00";
  },
  calculateAge(birthdayString) {
    if (!birthdayString) {
      return "";
    }
    const birthday = new Date(birthdayString);
    const ageDifference = Date.now() - birthday;
    const temp = new Date(ageDifference);
    return Math.abs(temp.getUTCFullYear() - 1970).toString();
  },
  validateUuid(uuidString) {
    return (
      uuidString && typeof uuidString == "string" && uuidString.length === 36
    );
  },
  validateJaneUploadFilename(filename) {
    return (
      filename &&
      typeof filename === "string" &&
      filename.split(".").pop() === "csv"
    );
  },
  getAccessionId(patientNumber, arrivedAt) {
    if(!patientNumber){
      return ""
    }
    return (
      patientNumber.toString() + "-" + arrivedAt.toString().replace(/\D/g, "")
    );
  },
  computeReportedOn(testData) {
    // Specifically format the reported_on date in just the right way
    const today = new Date();

    // BVM Special request: If the arrived_at time is less than 20 minutes ago, make reported on 20 minutes after
    // The arrived_at time. Otherwise, just use right now.
    const arrivedAtDate = new Date(testData.arrived_at);
    const twenty_minutes_ms = 20 * 60 * 1000;

    if (today - arrivedAtDate < twenty_minutes_ms) {
      today.setTime(arrivedAtDate.getTime() + twenty_minutes_ms); // Use 20 minutes after the arrived at
    }

    // This is all to make a nice string
    const date = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
    const time = `${today.getHours().toString().padStart(2, "0")}:${today
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;

    return date + " " + time;
  },
};
