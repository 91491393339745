<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="1500px" scrollable>
      <v-card>
        <v-card-title class="headline custom-card-header"
        >{{ title }}
          <v-spacer></v-spacer>
          <v-btn color="white" icon @click="handleClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="pt-4">
              <v-col cols="5">
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                        v-if="this.report.id"
                        name="report_number"
                        v-model="report.reportNumber"
                        label="Report Number*"
                        required
                        disabled
                        :readonly="!wantEdit"
                        :rules="rules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        name="report_uuid"
                        v-model="report.reportUuid"
                        label="Report Uuid*"
                        disabled
                        v-if="this.report.id"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div class="font-weight-bold pt-4" style="font-size: 1.3em">
                  Contact Info
                </div>
                <v-divider class="divider"></v-divider>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                        name="first_name"
                        v-model="report.firstName"
                        label="First Name*"
                        required
                        :readonly="!wantEdit"
                        :rules="rules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        name="last_name"
                        v-model="report.lastName"
                        label="Last Name*"
                        required
                        :readonly="!wantEdit"
                        :rules="rules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-text-field
                    name="email"
                    v-model="report.email"
                    label="Email*"
                    required
                    :readonly="!wantEdit"
                    :rules="rules"
                ></v-text-field>
                <v-text-field
                    name="phone_number"
                    v-model="report.phoneNumber"
                    label="Phone Number"
                    :readonly="!wantEdit"
                ></v-text-field>
                <v-row>
                  <v-col cols="12">
                    <div class="font-weight-bold pt-4" style="font-size: 1.3em">
                      Sighting Info
                    </div>
                    <v-divider class="divider"></v-divider>
                    <div class="font-weight-bold pt-5" style="font-size: 1.2em">
                      Are you reporting an incident you personally witnessed?*
                    </div>
                    <v-radio-group v-model="report.isFirstHand" mandatory row :readonly="!wantEdit">
                      <v-radio
                          label="I witnessed this first-hand"
                          :value="true"
                      ></v-radio>
                      <v-radio
                          label="This incident was reported to me"
                          :value="false"
                      ></v-radio>
                    </v-radio-group>
                    <v-row>
                      <v-col cols="7">
                        <v-text-field
                            name="vessel_type"
                            v-model="report.vesselType"
                            label="Vessel Type*"
                            required
                            :readonly="!wantEdit"
                            :rules="rules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="5">
                        <v-text-field
                            name="vessel_name"
                            v-model="report.vesselName"
                            label="Vessel Name"
                            :readonly="!wantEdit"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="10">
                    <v-row>
                      <v-menu
                          v-model="sightingTimeDateField"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          dense
                          class="arrived-at-date-picker"
                          :disabled="!wantEdit"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="report.sightingTime"
                              label="Sighting Time"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :rules="rules"
                              class="mx-2"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="tempDateSighting"></v-date-picker>
                        <v-row
                            class="arrived-at-date-picker"
                            style="width: 310px"
                        >
                          <v-col cols="4" class="pl-4 pb-4">
                            <v-text-field
                                v-model="hour"
                                hide-details
                                type="number"
                                outlined
                                label="Hour"
                                name="hour"
                                dense
                                :rules="hourRules"
                            />
                          </v-col>
                          <v-col cols="4" class="pl-0">
                            <v-text-field
                                v-model="minute"
                                hide-details
                                type="number"
                                outlined
                                label="Minute"
                                name="minute"
                                dense
                                :rules="minuteRules"
                            />
                          </v-col>
                          <v-col cols="3" class="pl-0">
                            <v-btn
                                color="primary"
                                class="py-5"
                                small
                                :disabled="!validDateSighting"
                                @click.native="saveSightingTime"
                            >
                              <v-icon>mdi-check</v-icon>
                              SAVE
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-menu>

                      <v-menu
                          v-model="reportTimeDateField"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          dense
                          class="arrived-at-date-picker"
                          :disabled="!wantEdit"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="report.reportDate"
                              label="Report Time"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :rules="rules"
                              class="mx-2"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="tempDateReport"></v-date-picker>
                        <v-row
                            class="arrived-at-date-picker"
                            style="width: 310px"
                        >
                          <v-col cols="4" class="pl-4 pb-4">
                            <v-text-field
                                v-model="hour"
                                hide-details
                                type="number"
                                outlined
                                label="Hour"
                                name="hour"
                                dense
                                :rules="hourRules"
                            />
                          </v-col>
                          <v-col cols="4" class="pl-0">
                            <v-text-field
                                v-model="minute"
                                hide-details
                                type="number"
                                outlined
                                label="Minute"
                                name="minute"
                                dense
                                :rules="minuteRules"
                            />
                          </v-col>
                          <v-col cols="3" class="pl-0">
                            <v-btn
                                color="primary"
                                class="py-5"
                                small
                                :disabled="!validDateReport"
                                @click.native="saveReportTime"
                            >
                              <v-icon>mdi-check</v-icon>
                              SAVE
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-menu>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="5">
                        <v-text-field
                          name="lat"
                          v-model="report.lat"
                          label="Sighting Location Latitude*"
                          :rules="lonLatRules"
                          type="number"
                          :readonly="!wantEdit"
                          hide-spin-buttons
                        ></v-text-field>
                      </v-col>
                      <v-col cols="5">
                        <v-text-field
                          name="lon"
                          v-model="report.lon"
                          label="Sighting Location Longitude*"
                          :rules="lonLatRules"
                          type="number"
                          :readonly="!wantEdit"
                          hide-spin-buttons
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-menu
                            v-model="mapField"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            dense
                            class="map"
                            :disabled="!wantEdit"
                        >
                          <template v-slot:activator="{ on: onMenu, attrs }">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on: onTooltip }">
                                <v-btn
                                    x-small
                                    v-on="{ ...onMenu, ...onTooltip }"
                                    v-bind="attrs"
                                    class="mt-6 py-3"
                                >
                                  <v-icon>
                                    mdi-map-marker-account
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Open Map</span>
                            </v-tooltip>
                          </template>
                          <v-row class="map-container">
                            <div id="map" class="map"></div>
                          </v-row>
                          <v-row class="map-buttons">
                            <v-text-field
                                name="lat"
                                v-model="mapLocation[1]"
                                label="Sighting Location Latitude"
                                :rules="lonLatRules"
                                type="number"
                                :readonly="!wantEdit"
                                class="mx-2"
                                dense
                            ></v-text-field>
                            <v-text-field
                                name="lon"
                                v-model="mapLocation[0]"
                                label="Sighting Location Longitude"
                                :rules="lonLatRules"
                                type="number"
                                :readonly="!wantEdit"
                                class="mx-2"
                                dense
                            ></v-text-field>
                            <v-spacer/>
                            <v-btn
                                color="primary"
                                class="py-4 mb-2"
                                small
                                @click.native="saveLocation"
                            >
                              <v-icon>mdi-check</v-icon>
                              SAVE
                            </v-btn>
                          </v-row>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                        name="location_description"
                        label="Location Description*"
                        outlined
                        v-model="report.locationDescription"
                        :rules="rules"
                        :readonly="!wantEdit"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <div class="font-weight-bold pt-4" style="font-size: 1.3em">
                  Event Info
                </div>
                <v-divider class="divider"></v-divider>
                <v-row class="pb-6">
                  <v-col cols="6">
                    <v-select
                        class="pt-4"
                        :items="Object.values(enums.species)"
                        label="Species*"
                        v-model="report.species"
                        outlined
                        :rules="rules"
                        :readonly="!wantEdit"
                    ></v-select>
                    <v-text-field
                        v-if="report.species === 'Other'"
                        name="other_species"
                        v-model="specOtherValue"
                        label="Please Specify*"
                        required
                        :rules="rules"
                        :readonly="!wantEdit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <div class="font-weight-bold pt-5" style="font-size: 1.2em">
                      Was the animal deceased?*
                    </div>
                    <v-radio-group v-model="report.isDeceased" column mandatory :readonly="!wantEdit">
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                    <v-text-field
                        name="carcass_location"
                        v-model="report.carcassLocation"
                        label="Carcass Location"
                        :disabled="!report.isDeceased"
                        :readonly="!wantEdit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                        class="pt-4"
                        :items="Object.values(enums.incidentType)"
                        label="Incident Type*"
                        v-model="report.incidentNature"
                        outlined
                        :rules="rules"
                        :readonly="!wantEdit"
                    ></v-select>
                    <v-text-field
                        v-if="report.incidentNature === 'Other'"
                        name="other_incident_type"
                        v-model="incOtherValue"
                        label="Please Specify*"
                        required
                        :rules="rules"
                        :readonly="!wantEdit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                        class="pt-4"
                        :items="Object.values(enums.injuryLocation)"
                        label="Injury Location*"
                        v-model="report.injuryLocation"
                        outlined
                        :rules="rules"
                        :readonly="!wantEdit"
                    ></v-select>
                    <v-text-field
                        v-if="report.injuryLocation === 'Other'"
                        name="other_vessel_type"
                        v-model="locOtherValue"
                        label="Please Specify*"
                        required
                        :rules="rules"
                        :readonly="!wantEdit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                        v-if="report.incidentNature === 'Ship Strike'"
                        name="incident_vessel_type"
                        v-model="report.incidentVesselType"
                        label="Type of Vessel Involved in Incident*"
                        required
                        :rules="rules"
                        :readonly="!wantEdit || sameVessel"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                        v-if="report.incidentNature === 'Ship Strike'"
                        name="incident_vessel_name"
                        v-model="report.incidentVesselName"
                        label="Name of Vessel Involved in Incident"
                        required
                        :rules="rules"
                        :readonly="!wantEdit || sameVessel"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                        v-if="report.incidentNature === 'Ship Strike'"
                        name="same_vessel"
                        label="Same as Reporting Vessel"
                        v-model="sameVessel"
                        :readonly="!wantEdit"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                        class="pt-4"
                        :items="Object.values(enums.travDirection)"
                        label="Direction of Travel*"
                        v-model="report.travelDirection"
                        outlined
                        :rules="rules"
                        :readonly="!wantEdit"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-textarea
                        name="other_vessel_type"
                        v-model="report.behaviourDescription"
                        label="Description of Animal's Behaviour"
                        required
                        outlined
                        :rules="rules"
                        :readonly="!wantEdit"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <div class="font-weight-bold pt-4" style="font-size: 1.2em">
                  Additional Info <small>(Optional)</small>
                </div>
                <v-divider class="divider"></v-divider>
                <v-col cols="12">
                  <v-textarea
                      name="type_of_entangling_gear"
                      label="Type of Entangling Gear"
                      outlined
                      v-model="report.typeOfEntanglingGear"
                      v-if="report.incidentNature == 'Entanglement'"
                      :readonly="!wantEdit"
                  ></v-textarea>
                  <v-textarea
                      name="visible_injury_description"
                      label="Visible Injury Description"
                      outlined
                      v-model="report.visibleInjuryDescription"
                      :readonly="!wantEdit"
                  ></v-textarea>
                  <v-textarea
                      name="additional_notes"
                      label="Additional Notes"
                      outlined
                      v-model="report.notes"
                      :readonly="!wantEdit"
                  ></v-textarea>
                  <v-row>
                    <v-col cols="4">
                      <div class="font-weight-bold pt-4" style="font-size: 1.2em">
                        Were photos taken?
                      </div>
                      <v-radio-group v-model="report.photosTaken" row :readonly="!wantEdit">
                        <v-radio label="Yes" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="8">
                      <div class="font-weight-bold pt-4" style="font-size: 1.2em">
                        Has this incident already been reported to DFO?
                      </div>
                      <v-radio-group v-model="report.isDfoReported" row :readonly="!wantEdit">
                        <v-radio label="Yes" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                      <v-text-field
                          v-if="report.isDfoReported"
                          name="dfo_report_number"
                          v-model="report.dfoReportNumber"
                          label="DFO Report Number"
                          required
                          :rules="rules"
                          :readonly="!wantEdit"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-row> </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card class="bottom-sticky">
          <v-card-actions>
            <v-btn @click.native="handleClose">
              <v-icon>mdi-close</v-icon>
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn v-if="this.report.id" @click.native="toggleEditConfirmDialog(true)" :disabled="wantEdit">
              <v-icon>mdi-pencil</v-icon>
              Enable Editing
            </v-btn>
            <v-spacer />
            <v-btn v-if="this.report.id" @click.native="toggleEmailDialog(true)">
              <v-icon class="pr-1">mdi-email</v-icon>
              Send Email
            </v-btn>
            <v-spacer />
            <v-btn color="primary" :disabled="!valid || !wantEdit" @click.native="save">
              <v-icon>mdi-check</v-icon>
              <span v-show="!saving">{{ button }}</span>
              <pulse-loader :loading="saving"></pulse-loader>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
    <edit-confirm
        :action="enableEditing"
        :dialog="editConfirmDialog"
        :close="toggleEditConfirmDialog"
    ></edit-confirm>
    <email
        :dialog="emailDialog"
        :close="toggleEmailDialog"
    ></email>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Utils from "@/helpers/utils";
import PulseLoader from "vue-spinner/src/PulseLoader";
import enums from "@/helpers/enums";
import mapboxgl from "mapbox-gl"
import editConfirm from "@/components/EditConfirm";
import email from "@/components/Email";
import "mapbox-gl/dist/mapbox-gl.css";

export default {
  components: {
    PulseLoader,
    editConfirm,
    email,
  },

  data() {
    return {
      accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
      mapStyle: "mapbox://styles/mapbox/outdoors-v11",
      mapCenter: [-128.9110, 54.6057],
      zoom: 4.15,
      map: {},
      marker: null,
      navigation: null,
      geolocation: null,
      scale: null,
      selectedReport: {},
      lastVisitDateField: false,
      birthdateDateField: false,
      sightingTimeDateField: false,
      reportTimeDateField: false,
      tempDateSighting: null,
      tempDateReport: null,
      hour: null,
      minute: null,
      mapField: false,
      mapLocation: [],
      wantEdit: false,
      valid: true,
      rules: [(v) => !!v || "This field is required!"],
      lonLatRules: [(v) => !!v || "Please enter a number!"],
      hourRules: [
        (v) =>
            (v && v >= 0) ||
            "Hour should be between 0 (midnight) and 23 (11:00 PM).",
        (v) =>
            (v && v <= 23) ||
            "Hour should be between 0 (midnight) and 23 (11:00 PM).",
      ],
      minuteRules: [
        (v) => (v && v >= 0) || "Minute should be between 0 and 59.",
        (v) => (v && v <= 59) || "Minute should be between 0 and 59.",
      ],
      specOtherValue: "",
      incOtherValue: "",
      locOtherValue: "",
      editConfirmDialog: false,
      emailDialog: false,
      sameVessel: false,
    };
  },

  computed: {
    enums() {
      return enums;
    },
    ...mapGetters({
      saving: "report/details/saving",
      saved: "report/details/saved",
      report: "report/details/report",
      dialog: "report/details/dialog",
      button: "report/details/button",
      title: "report/details/title",
      specOtherValueGetter: "report/details/specOtherValue",
      incOtherValueGetter: "report/details/incOtherValue",
      locOtherValueGetter: "report/details/locOtherValue",
    }),
    formattedDate: {
      get() {
        return Utils.formatIsoDate(this.report.sightingTime);
      },
      set(newVal) {
        this.report.sightingTime = Utils.toIsoDateString(newVal);
      },
    },
    validDateSighting() {
      return this.tempDateSighting && this.hour && this.minute;
    },
    validDateReport() {
      return this.tempDateReport && this.hour && this.minute;
    },
  },
  watch: {
    report() {
      this.selectedReport = this.report;
      if (!this.report.id) {
        this.wantEdit = true;
      }
      else {
        this.wantEdit = false;
      }
      this.datetime = this.report?.sightingTime.split(" ");
      this.time = this.datetime[1];
      this.hour = this.time?.split(":")[0];
      this.minute = this.time?.split(":")[1];
      this.tempDateSighting = this.datetime[0];
    },
    hour() {
      if (this.hour < 0) {
        this.hour = 23;
      } else if (this.hour > 23) {
        this.hour = 0;
      }
    },
    minute() {
      if (this.minute < 0) {
        this.minute = 59;
      } else if (this.minute > 59) {
        this.minute = 0;
      }
    },
    specOtherValueGetter() {
      this.specOtherValue = this.specOtherValueGetter;
    },
    incOtherValueGetter() {
      this.incOtherValue = this.incOtherValueGetter;
    },
    locOtherValueGetter() {
      this.locOtherValue = this.locOtherValueGetter;
    },
    mapField() {
      //nextTick ensures the menu loads before the map, the timeout forces the map to render at the correct size
      this.$nextTick(setTimeout(() => this.createMap(), 100));
    },
    sameVessel() {
      if (this.sameVessel) {
        this.report.incidentVesselName = this.report.vesselName;
        this.report.incidentVesselType = this.report.vesselType;
      }
      else {
        this.report.incidentVesselName = "";
        this.report.incidentVesselType = "";
      }
    },
    "report.vesselName"() {
      if (this.sameVessel) {
        this.report.incidentVesselName = this.report.vesselName;
      }
    },
    "report.vesselType"() {
      if (this.sameVessel) {
        this.report.incidentVesselType = this.report.vesselType;
      }
    },
  },
  created() {

  },
  methods: {
    ...mapActions({
      update: "report/details/update",
      create: "report/details/create",
      close: "report/details/close",
    }),
    async save() {
      await this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      this.saveOtherBoxValues();
      this.resetRedundantValues();
      if (this.report.id) {
        await this.update({ report: this.report });
      } else {
        await this.create({ report: this.report });
      }
    },
    async saveSightingTime() {
      this.formattingDate = "";
      if (
          this.hour.toString().length < 2 &&
          this.minute.toString().length < 2
      ) {
        this.formattingDate = `${this.tempDateSighting} 0${this.hour}:0${this.minute}`;
      } else if (this.hour.toString().length < 2) {
        this.formattingDate = `${this.tempDateSighting} 0${this.hour}:${this.minute}`;
      } else if (this.minute.toString().length < 2) {
        this.formattingDate = `${this.tempDateSighting} ${this.hour}:${this.minute}`;
      } else {
        this.formattingDate = `${this.tempDateSighting} ${this.hour}:${this.minute}`;
      }
      this.report.sightingTime = this.formattingDate;
      this.sightingTimeDateField = false;
    },
    async saveReportTime() {
      this.formattingDate = "";
      if (
          this.hour.toString().length < 2 &&
          this.minute.toString().length < 2
      ) {
        this.formattingDate = `${this.tempDateReport} 0${this.hour}:0${this.minute}`;
      } else if (this.hour.toString().length < 2) {
        this.formattingDate = `${this.tempDateReport} 0${this.hour}:${this.minute}`;
      } else if (this.minute.toString().length < 2) {
        this.formattingDate = `${this.tempDateReport} ${this.hour}:${this.minute}`;
      } else {
        this.formattingDate = `${this.tempDateReport} ${this.hour}:${this.minute}`;
      }
      this.report.reportDate = this.formattingDate;
      this.reportTimeDateField = false;
    },
    createMap() {
      try {
        mapboxgl.accessToken = this.accessToken;
        this.map = new mapboxgl.Map({
          container: "map",
          style: this.mapStyle,
          center: this.mapCenter,
          zoom: this.zoom,
          attributionControl: false,
        });

        this.navigation = new mapboxgl.NavigationControl()
        this.geolocation = new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
        })
        this.scale = new mapboxgl.ScaleControl()

        this.map.scrollZoom.disable();
        this.map.addControl(this.navigation)
        this.map.addControl(this.geolocation)

        this.marker = new mapboxgl.Marker();

        this.map.on("click", (e) => this.moveMarker(e));
      } catch (err) {
        console.log("map error", err);
      }
    },
    moveMarker (event) {
      this.marker.setLngLat(event.lngLat).addTo(this.map);
      this.mapLocation = [event.lngLat.lng, event.lngLat.lat]
    },
    async saveLocation() {
      this.report.lon = this.mapLocation[0];
      this.report.lat = this.mapLocation[1]
      this.mapField = false;
    },
    saveOtherBoxValues() {
      if (this.report.species === "Other") {
        this.report.species = this.specOtherValue;
      }
      if (this.report.incidentNature === "Other") {
        this.report.incidentNature = this.incOtherValue;
      }
      if (this.report.injuryLocation === "Other") {
        this.report.injuryLocation = this.locOtherValue;
      }
    },
    resetRedundantValues() {
      if (this.report.incidentNature !== "Entanglement") {
        this.report.typeOfEntanglingGear = "";
      }
      if (this.report.incidentNature !== "Ship Strike") {
        this.report.incidentVesselName = "";
        this.report.incidentVesselType = "";
      }
      if (!this.report.isDeceased) {
        this.report.carcassLocation = "";
      }
    },
    toggleEditConfirmDialog(dialog) {
      this.editConfirmDialog = dialog
    },
    enableEditing() {
      this.wantEdit = true;
    },
    toggleEmailDialog(dialog) {
      this.emailDialog = dialog;
    },
    handleClose() {
      this.$refs.form.resetValidation();
      this.close();
      this.wantEdit = false;
    },
  },
};
</script>

<style>
.map {
  height: 67vh;
  width: 40rem;
  margin: 5vh 0.7rem 0 0.7rem;
}

.map-buttons {
  background-color: white;
  padding: 0.5rem 1rem 0;
}

.map-container {
  position: relative !important;
  margin-top: -2.5rem;
  background-color: white;
}

.custom-card-header {
  background-color: #364563;
  color: white;
}

.divider {
  border-top-width: 3px;
}

.bottom-sticky {
  position: sticky;
  bottom: 0;
  z-index: 998;
}

.arrived-at-date-picker {
  background-color: white;
}
</style>
