import Vue from "vue";
import Vuex from "vuex";

import auth from "@/store/modules/auth";
import users from "@/store/modules/users";
import delete_user from "@/store/modules/delete";
import userDetails from "@/store/modules/user/details";
import reports from "@/store/modules/reports";
import reportsDetails from "@/store/modules/reports/details";

import vuexLocal from "@/plugins/vue-persist";
import dashboard from "@/store/modules/dashboard";
import reset from "@/store/modules/reset";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth: auth,
    reset: reset,
    users: users,
    delete: delete_user,
    report: {
      namespaced: true,
      modules: {
        details: reportsDetails,
      },
    },
    user: {
      namespaced: true,
      modules: {
        details: userDetails,
      },
    },
    reports: reports,
    dashboard: dashboard,
  },
  plugins: [vuexLocal.plugin],
});

export default store;
