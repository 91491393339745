<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card class="mx-auto" align-self="center" max-width="950">
          <v-card-title justify="space-around" class="primary text-center">
            <humpback-logo
                class="appbar-vertical-align hidden-xs-only"
            ></humpback-logo>
            <span
                class="text-h4 white--text pl-4"
                style="word-break: break-word"
            >BCWhales Incident Reporter
            </span>
            <v-spacer/>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="text-center">
            <v-row class="mt-lg-6">
              <v-col cols="12" lg="5">
                <v-row justify="center" class="pa-2">
                  <v-col>
                     <span class="text-h5">
                      DFO Contact information and Incident Reporting Line:
                    </span>
                    <br/>
                    <br/>
                    <span class="text-body-1">
                      1-800-465-4336
                      <br/>
                       FO.ORR-ONS.MPO@dfo-mpo.gc.ca
                      <br/>
                       VHF Channel 16
                    </span>

                    <br/>
                    <br/>

                    <a
                        href="https://www.dfo-mpo.gc.ca/species-especes/mammals-mammiferes/watching-observation/index-eng.html"
                        target="_blank"
                    >
                      <span class="text-body-1">
                       Fisheries and Oceans Canada Marine mammal viewing and approach regulations
                      </span>
                    </a>

                  </v-col>
                </v-row>
              </v-col>
              <v-divider vertical v-if="!isMobile"></v-divider>
              <v-col cols="12" lg="7">
                <v-row justify="center" class="submit-button-caption">
                  <v-col>
                    <span class="text-h5">
                      Welcome to the BCWhales Incident Reporter!
                    </span>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col>
                    <v-btn class="px-5 py-6 submit-button" color="primary" to="/public_report">
                      Submit Report
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions v-if="!isMobile">
            <v-row class="pb-1">
              <v-btn text color="primary" to="/privacy-policy" class="ma-2">
                <span>Privacy Policy</span>
              </v-btn>
              <v-btn text color="primary" to="/account-management" class="ma-2">
                <span>Account Management</span>
              </v-btn>
              <v-spacer/>
              <v-btn text color="primary" to="/login" class="ma-2">
                <span>Log In</span>
              </v-btn>
              <v-btn text color="primary" to="/register" class="ma-2">
                <span>Register</span>
              </v-btn>
            </v-row>
          </v-card-actions>
          <v-card-actions v-if="isMobile">
            <v-row>
              <v-btn text color="primary" to="/privacy-policy" class="ma-2">
                <span>Privacy Policy</span>
              </v-btn>
              <v-btn text color="primary" to="/account-management" class="ma-2">
                <span>Account Management</span>
              </v-btn>
            </v-row>
            <v-spacer/>
            <v-row>
              <v-btn text color="primary" to="/login" class="ma-2">
                <span>Log In</span>
              </v-btn>
              <v-btn text color="primary" to="/register" class="ma-2">
                <span>Register</span>
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import HumpbackLogo from "@/components/svgs/HumpbackLogo";
// import "element-plus/theme-chalk/display.css";

export default {
  components: {
    HumpbackLogo,
  },

  data() {
    return {
      email: "",
      password: "",
      forgotPasswordPath: "/forgotPassword",
    };
  },
  created() {
    this.reset();
    if (this.isTokenSet) {
      if (this.role === "admin") {
        this.$router.push({name: "dashboard"});
      }
      this.$router.push({name: "reports"});
    }
  },
  computed: {
    ...mapGetters({
      loading: "auth/loading",
      isTokenSet: "auth/isTokenSet",
      message: "auth/message",
      role: "auth/role",
    }),
    isMobile() {
      const breakpoint = this.$vuetify.breakpoint.name;
      return breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md" ;
    },
  },
  methods: {
    ...mapActions({
      reset: "auth/reset",
      login: "auth/login",
    }),
    async submit() {
      await this.login({
        email: this.email,
        password: this.password,
      });
      this.password = null;
    },
  },
};
</script>

<style scoped>
.submit-button {
  font-size: large;
}
@media screen and (min-width: 1001px) {
  .submit-button-caption {
    margin-top: 5vh;
  }
}
</style>
