<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <form @submit.prevent="submit">
          <v-card class="mx-auto" align-self="center" max-width="650">
            <v-card-title justify="space-around" class="primary">
              <v-btn color="white" icon to="/home">
                <v-icon large>mdi-arrow-left</v-icon>
              </v-btn>
              <span
                class="text-h5 white--text pl-4"
                style="word-break: break-word"
                >Register for Incident Reporter</span
              >
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-show="!registered">
              <v-text-field
                label="First Name"
                v-model="user.firstName"
              ></v-text-field>
              <v-text-field
                label="Last Name"
                v-model="user.lastName"
              ></v-text-field>
              <v-text-field
                label="Email"
                v-model="user.email"
                :rules="emailValidation"
              ></v-text-field>
              <v-text-field
                label="Password"
                v-model="password"
                type="password"
                :rules="passwordValidation"
              ></v-text-field>
              <v-text-field
                label="Confirm Password"
                v-model="confirmPassword"
                type="password"
                :rules="confirmValidation"
              ></v-text-field>
            </v-card-text>
            <v-card-text
              v-if="this.message && registered"
              style="font-size: medium"
            >
              {{ this.message }}
            </v-card-text>
            <v-card-text
              v-if="this.message && !registered"
              style="font-size: medium; color: red"
            >
              {{ this.message }}
            </v-card-text>
            <v-card-actions>
              <v-row class="ma-2" justify="end">
                <v-btn
                  class="ml-2"
                  v-if="!registered"
                  color="primary"
                  type="submit"
                >
                  <span>Register</span>
                </v-btn>
                <v-btn
                  class="ml-2"
                  v-if="registered"
                  color="primary"
                  type="placeholder"
                  link
                  :to="this.homePath"
                >
                  <span>Login</span>
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import HumpbackLogo from "@/components/svgs/HumpbackLogo";

export default {
  components: {
    // HumpbackLogo,
  },

  data() {
    return {
      password: "",
      confirmPassword: "",
      selectedRoleId: "3", // User Role
      emailValidation: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      passwordValidation: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length >= 6) || "Password must be greater than 6 characters",
      ],
      confirmValidation: [
        (v) => !!v || "Confirm password is required",
        (v) =>
          v === this.password || "The password confirmation does not match.",
      ],
      registered: false,
      saveFailed: false,
      registerAccountPath: "/register",
      homePath: "/",
    };
  },
  watch: {
    user(user) {
      this.selectedRoleId = user ? user.roleId : null;
      this.setMessage("");
      this.password = user ? user.password : null;
    },
  },
  created() {
    this.reset();
    if (this.isTokenSet) {
      if (this.role === "admin") {
        this.$router.push({ name: "dashboard" });
      }
      this.$router.push({ name: "reports" });
    }
  },
  computed: {
    ...mapGetters({
      user: "user/details/user",
      message: "user/details/message",
    }),
  },
  methods: {
    ...mapActions({
      register: "user/details/register",
      close: "user/details/close",
      setMessage: "user/details/setMessage",
    }),
    checkCanSave() {
      return (
        this.password === this.confirmPassword && this.password.length >= 6
      );
    },
    async submit() {
      if (this.checkCanSave()) {
        try {
          await this.save();
          await this.register(this.user);
          await this.setMessage(
            "You have successfully registered an account, proceed to login."
          );
          this.confirmPassword = "";
          this.registered = true;
        } catch (e) {
          this.registered = false;
          if (this.saveFailed) {
            await this.setMessage(e);
          } else {
            await this.close();
            await this.setMessage(
              "Something went wrong. If you already have an account associated with this email, try logging in or contact info@bcwhales.org for help."
            );
            this.confirmPassword = "";
          }
        }
      }
    },
    async save() {
      this.saveFailed = true;
      this.user.password = this.password;
      if (!this.user.firstName) {
        throw Error("The user must have a first name!");
      }
      if (!this.user.lastName) {
        throw Error("The user must have a last name!");
      }
      if (!this.user.password) {
        throw Error("The user must have a password!");
      }
      if (!this.confirmPassword) {
        throw Error("Must confirm the password!");
      }
      if (!this.user.email) {
        throw Error("The user must have a email!");
      }
      this.user.roleId = this.selectedRoleId;
      this.saveFailed = false;
    },
  },
};
</script>
