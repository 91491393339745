<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card class="mx-auto" :class="cardClass" align-self="center">
          <v-card-title justify="space-around" class="primary">
            <v-btn color="white" icon to="/home">
              <v-icon large>mdi-arrow-left</v-icon>
            </v-btn>
            <span
                class="text-h5 white--text pl-4"
                style="word-break: break-word"
                v-if="!isMobile"
            >
              Need To Manage Your Account?
            </span>
            <span
                class="text-h6 white--text pl-2"
                style="word-break: break-word"
                v-if="isMobile"
            >
              Need To Manage Your Account?
            </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: auto">
            <div style="font-size: large" class="mt-6">
              <span>
                Remote Report makes account management easy. Please follow the steps outlined below to manage your account settings.
              </span>
              <br/>
              <br/>
              <br/>
              <br/>
              <span class="text-h6">
                Access Account Settings
              </span>
              <br/>
              <br/>
              <span>
                1. Login to your Remote Report account.
              </span>
              <br/>
              <br/>
              <v-row v-if="!isMobile" class="cardImages">
                <img src="../assets/remove_account_1.png" alt="Account settings step 1" height="500" class="ml-2"/>
                <img src="../assets/remove_account_2.png" alt="Account settings step 2" height="500" class="ml-4"/>
              </v-row>
              <v-row v-if="isMobile" class="cardImages">
                <img src="../assets/remove_account_1.png" alt="Account settings step 1" height="500" class="ml-2"/>
              </v-row>
              <v-row v-if="isMobile" class="cardImages">
                <img src="../assets/remove_account_2.png" alt="Account settings step 2" height="500" class="ml-2 mt-2"/>
              </v-row>
              <br/>
              <br/>
                <span>
                  2. Navigate to "Account Settings".
                </span>
                <br/>
                <br/>
                <v-row class="cardImages">
                  <img src="../assets/remove_account_3.png" alt="Account settings step 3" height="500" class="ml-2"/>
                </v-row>
              <br/>
              <br/>
              <br/>
              <br/>
              <span class="text-h6">
                Edit Account Information
              </span>
              <br/>
              <br/>
              <div v-if="!isMobile">
              <span>
                Select "Edit" and, once you have made your changes, select "Save".
              </span>
                <br/>
                <br/>
                <v-row class="cardImages">
                  <img src="../assets/edit_account_1.png" alt="Account edit step 1" height="500" class="ml-2"/>
                  <img src="../assets/edit_account_2.png" alt="Account edit step 2" height="500" class="ml-2"/>
                </v-row>
              </div>
              <div v-if="isMobile">
              <span>
                1. Select "Edit".
              </span>
                <br/>
                <br/>
                <v-row class="cardImages">
                  <img src="../assets/edit_account_1.png" alt="Account edit step 1" height="500" class="ml-2"/>
                </v-row>
                <br/>
                <br/>
                <span>
                2. Once you have made your changes, select "Save".
              </span>
                <br/>
                <br/>
                <v-row class="cardImages">
                  <img src="../assets/edit_account_2.png" alt="Account edit step 2" height="500" class="ml-2"/>
                </v-row>
              </div>
              <br/>
              <br/>
              <br/>
              <br/>
              <span class="text-h6">
                Change Password
              </span>
              <br/>
              <br/>
              <div v-if="!isMobile">
              <span>
                Select "Change Password", enter your old and new passwords, and select "Save".
              </span>
                <br/>
                <br/>
                <v-row class="cardImages">
                  <img src="../assets/change_password_1.png" alt="Change password step 1" height="500" class="ml-2"/>
                  <img src="../assets/change_password_2.png" alt="Change password step 2" height="500" class="ml-2"/>
                </v-row>
              </div>
              <div v-if="isMobile">
              <span>
                1. Select "Change Password".
              </span>
                <br/>
                <br/>
                <v-row class="cardImages">
                  <img src="../assets/change_password_1.png" alt="Change password step 1" height="500" class="ml-2"/>
                </v-row>
                <br/>
                <br/>
                <span>
                2. Enter your old and new passwords and select "Save".
              </span>
                <br/>
                <br/>
                <v-row class="cardImages">
                  <img src="../assets/change_password_2.png" alt="Change password step 2" height="500" class="ml-2"/>
                </v-row>
              </div>
              <br/>
              <br/>
              <br/>
              <br/>
              <span class="text-h6">
                Delete Account
              </span>
              <br/>
              <br/>
              <div v-if="!isMobile">
              <span>
                Select "Delete Account" and in the next screen select "Confirm Delete".
              </span>
              <br/>
              <br/>
              <v-row class="cardImages">
                <img src="../assets/remove_account_4.png" alt="Account deletion step 1" height="500" class="ml-2"/>
                <img src="../assets/remove_account_5.png" alt="Account deletion step 2" height="500" class="ml-2"/>
              </v-row>
            </div>
            <div v-if="isMobile">
              <span>
                1. Select "Delete Account".
              </span>
              <br/>
              <br/>
              <v-row class="cardImages">
                <img src="../assets/remove_account_4.png" alt="Account deletion step 1" height="500" class="ml-2"/>
              </v-row>
              <br/>
              <br/>
              <span>
                2. Select "Confirm Delete".
              </span>
              <br/>
              <br/>
              <v-row class="cardImages">
                <img src="../assets/remove_account_5.png" alt="Account deletion step 2" height="500" class="ml-2"/>
              </v-row>
            </div>
              <br/>
              <br/>
              <br/>
              <span>
                If the above steps don't work for you, please fill out our
                <a href="/account-management-form">account management form</a> or
                contact grace@bcwhales.org directly with your name and email and
                and your account will be edited or deleted for you.
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  computed: {
    isMobile() {
      const breakpoint = this.$vuetify.breakpoint.name;
      return breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md";
    },
    cardClass() {
      if (this.isMobile) {
        return "cardClassMobile"
      }
      else {
        return "cardClassDesktop"
      }
    }
  },
};
</script>

<style scoped>
.cardClassDesktop {
  max-width: 35vw;
  text-align: center;
}
.cardClassMobile {
  max-width: 90vw;
  text-align: center;
}
.cardImages {
  display: flex;
  justify-content: center;
}
</style>
