<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="headline custom-card-header">
          Are you sure you want to edit this report?
        </v-card-title>
        <br />

        <v-card-text>
          <h4>You are about to edit RAW DATA. Any changes made here cannot be reverted once saved. Please exercise caution when editing. Do you wish to continue?</h4>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn @click.native="close(false)">
            <v-icon>mdi-cancel</v-icon>
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn @click.native="() => enableEditing()" color="primary">
            <v-icon>mdi-check</v-icon>
            Enable Editing
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["action", "dialog", "close"],

  methods: {
    async enableEditing() {
      await this.action();
      this.close(false);
    },
  },
};
</script>

<style scoped></style>
