<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent width="80vw" scrollable>
      <v-card>
        <v-card-title class="headline custom-card-header">
          Email Preview {{ report.reportNumber }}
          <v-spacer></v-spacer>
          <v-btn color="white" icon @click="close(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text--accent-1 preview-window">
          <div>
            From: {{ previewBody.from }}<br/>
            To: {{ previewBody.to }}<br/>
            Subject: {{ previewBody.subject }}<br/>
          </div>
          <br>
          <div v-html="emailBody"></div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click.native="close(false)">
            <v-icon>mdi-close</v-icon>
            Close
          </v-btn>
          <v-spacer />
          <v-btn color="primary" @click.native="submit" :disabled="!emails || !subject || saving">
            <v-icon>mdi-email</v-icon>
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["dialog", "customMessage", "subject", "emails", "action", "close"],

  data() {
    return {
      mailList: [],
    }
  },
  computed: {
    ...mapGetters({
      saving: "report/details/emailSaving",
      previewBody: "report/details/emailPreview",
      report: "report/details/report",
    }),
    emailBody() {
      // template engine adds unnecessary white space when passing to the frontend,
      // so it is processed and removed here
      return this.previewBody.html.replaceAll("\n", "").replaceAll("  ", "")
    },
  },
  methods: {
    ...mapActions({
    }),
    submit() {
      this.action();
      this.close();
    },
  },
};
</script>

<style>
  .preview-window {
    white-space: pre-wrap;
  }
</style>
