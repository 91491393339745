import api from "@/services/api/users";
import utils from "@/helpers/utils";

function formatUserForApi(user) {
  user.birthDate = utils.toIsoDateString(user.birthDate);
  user.lastVisit = utils.toIsoDateString(user.lastVisit);
  return user;
}

const state = {
  user: {},
  isSaving: false,
  isSaved: false,
  isDialogOpen: false,
  message: "",
};

const getters = {
  saving: (state) => state.isSaving,
  saved: (state) => state.isSaved,
  user: (state) => state.user,
  dialog: (state) => state.isDialogOpen,
  message: (state) => state.message,
  button: (state) => (state.user && state.user.id ? "Save" : "Create"),
  title: (state) =>
    state.user && state.user.id
      ? `Edit User ${state.user.id}`
      : "Create New User",
};

const actions = {
  open({ commit }, user) {
    commit("open", user);
  },
  close({ commit }) {
    commit("close");
  },
  async update({ commit, dispatch }, user) {
    commit("saving", { isSaving: true });
    const result = await api.update(user.id, formatUserForApi(user));

    if (result) {
      dispatch("users/reload", {}, { root: true });
      commit("saveComplete", { isSaveComplete: true });
    } else {
      commit("setMessage", "Updating the user failed!");
      commit("saveComplete", { isSaveComplete: false });
    }
  },
  async create({ commit, dispatch }, user) {
    commit("saving", { isSaving: true });
    const result = await api.create(formatUserForApi(user));

    if (result) {
      dispatch("users/reload", {}, { root: true });
      commit("saveComplete", { isSaveComplete: true });
    } else {
      commit("setMessage", "Creating the user failed!");
      commit("saveComplete", { isSaveComplete: false });
    }
  },
  async register({ commit, dispatch }, user) {
    commit("saving", { isSaving: true });
    const result = await api.register(user);

    if (result) {
      dispatch("users/reload", {}, { root: true });
      commit("saveComplete", { isSaveComplete: true });
    } else {
      commit("setMessage", "Registering the user failed!");
      commit("saveComplete", { isSaveComplete: false });
    }
  },
  setMessage({ commit }, message) {
    commit("setMessage", message);
  },
};

const mutations = {
  open(state, user) {
    state.user = { ...user };
    state.isDialogOpen = true;
  },
  close(state) {
    state.user = {};
    state.isDialogOpen = false;
  },
  saving(state, { isSaving }) {
    state.isSaving = isSaving;
  },
  saveComplete(state, { isSaveComplete }) {
    if (isSaveComplete) {
      state.isSaved = true;
      state.isDialogOpen = false;
      state.user = {};
    }
    state.isSaving = false;
  },
  setMessage(state, message) {
    state.message = message;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
