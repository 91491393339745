<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <form @submit.prevent="submitPassword">
          <v-card class="mx-auto" align-self="center" max-width="500">
            <v-card-title justify="space-around" class="primary">
              <humpback-logo
                  class="appbar-vertical-align hidden-xs-only"
              ></humpback-logo>
              <span class="text-h5 white--text pl-4"> Reset Password </span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 210px">
              <div>
                <div v-if="!saved">
                  <v-text-field
                    label="Password"
                    v-model="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    :rules="passwordValidation"
                    name="password"
                  ></v-text-field>
                  <v-text-field
                    label="Confirm Password"
                    v-model="confirmPassword"
                    prepend-icon="mdi-check"
                    type="password"
                    :rules="confirmValidation"
                    name="comfirmPassword"
                  ></v-text-field>
                </div>
              </div>
              <v-row
                v-if="saved"
                style="justify-content: center; font-size: medium"
                class="mt-6 ml-2"
              >
                {{ this.message }}
              </v-row>
              <v-row
                v-if="!saved"
                style="justify-content: center; font-size: medium"
                class="mt-1 ml-1"
              >
                {{ this.message }}
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row class="mb-4 mr-2" justify="end">
                <v-btn v-if="saved" color="primary" link :to="this.loginPath">
                  <span>Return to Login</span>
                </v-btn>
                <v-btn
                  v-if="!saved"
                  color="secondary"
                  link
                  :to="this.loginPath"
                >
                  <span>Return to Login</span>
                </v-btn>
                <v-btn
                  class="ml-2"
                  v-if="!saved"
                  color="primary"
                  type="submitPassword"
                >
                  <span>Save</span>
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import HumpbackLogo from "@/components/svgs/HumpbackLogo";


export default {
  components: {
    HumpbackLogo,
  },

  data() {
    return {
      message: "",
      password: "",
      confirmPassword: "",
      passwordValidation: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length >= 6) || "Password must be greater than 6 characters",
      ],
      confirmValidation: [
        (v) => !!v || "Confirm password is required",
        (v) =>
          v === this.password || "The password confirmation does not match.",
      ],
      saved: false,
      canSave: false,
      loginPath: "/login",
    };
  },
  async created() {
    this.message = "";
  },
  computed: {
    ...mapGetters({
      user: "reset/user",
    }),
  },
  methods: {
    ...mapActions({
      setUser: "reset/setUser",
      resetPassword: "reset/resetPassword",
      finish: "reset/finish",
    }),
    checkCanSave() {
      return (
        this.password === this.confirmPassword && this.password.length >= 6
      );
    },
    async submitPassword() {
      const searchQuery = window.location.search;
      const urlParam = new URLSearchParams(searchQuery);
      const token = urlParam.get("token");
      if (this.checkCanSave()) {
        try {
          await this.resetPassword({
            passToken: token,
            password: this.password,
          });
          this.confirmPassword = "";
          this.password = "";
          this.message =
            "We have successfully reset your password. To login with your new password click return to login below.";
          this.saved = true;
        } catch (e) {
          console.log(e);
          this.message =
            "Something went wrong. Try resending the reset password link, or contacting support.";
        }
      }
    },
  },
};
</script>
