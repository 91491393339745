<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h4 class="text-h4">
          <v-icon x-large class="pb-2">mdi-view-dashboard-variant</v-icon>
          Dashboard
        </h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <div class="font-weight-bold pt-4" style="font-size: 1.2em">
          Reports by Species
        </div>
        <v-divider class="divider"></v-divider>

        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Number of Reports</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="spec in Object.values(enums.species)" :key="spec">
              <td class="col-7">{{ spec }}</td>
              <td>{{ countSpecies(spec) }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="font-weight-bold pt-4" style="font-size: 1.2em">
          Reports by Incident
        </div>
        <v-divider class="divider"></v-divider>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Number of Reports</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="incident in Object.values(enums.incidentType)"
                :key="incident"
            >
              <td class="col-7">{{ incident }}</td>
              <td>{{ countIncident(incident) }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="font-weight-bold pt-4" style="font-size: 1.2em">
          Total Number of Reports Shown: {{items.length}}
        </div>
      </v-col>
      <v-col>
        <v-row dense class="filter-boxes">
          <v-col cols="6">
            <v-text-field
                type="number"
                v-model="numberOfPins"
                label="Number of pins"
                clearable
                prepend-icon="mdi-map-marker"
                @keydown="handleBackspace($event)"
                @keyup="executeSearch"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pr-6">
            <v-menu
                v-model="dateField"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dense
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateRangeText"
                    label="Date Filter"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="date = null"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="date"
                  range
                  show-adjacent-months
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row dense class="filter-boxes-2">
          <v-col cols="6">
            <v-select
                :items="enums.incidentType"
                v-model="selectedIncidentNatures"
                prepend-icon="mdi-alert-circle"
                label="Incident Nature"
                name="incident_nature"
                multiple
                clearable
                @keyup="executeSearch"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index < maxSelectDisplay">{{ item }} &nbsp;</span>
                <span
                    v-if="index === maxSelectDisplay"
                    class="grey--text caption"
                >(+{{ selectedIncidentNatures.length - maxSelectDisplay }} more)</span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-select
                :items="enums.species"
                v-model="selectedSpecies"
                prepend-icon="mdi-dolphin"
                label="Species"
                name="species"
                multiple
                clearable
                @keyup="executeSearch"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index < maxSelectDisplay">{{ item }} &nbsp;</span>
                <span
                    v-if="index === maxSelectDisplay"
                    class="grey--text caption"
                >(+{{ selectedSpecies.length - maxSelectDisplay }} more)</span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row dense>
          <MglMap
              class="map-class"
              :accessToken="accessToken"
              :mapStyle="mapStyle"
              :center="mapCenter"
              :zoom="zoom"
          >
            <MglNavigationControl></MglNavigationControl>
            <MglFullscreenControl></MglFullscreenControl>
            <MglMarker v-for="(item, index) in items"
                       :key="item.id"
                       :coordinates="[coordinates[index].lon, coordinates[index].lat]"
                       anchor="bottom"
                       :color="markerColor[index].color"
            >
              <MglPopup
                  close-button
                  close-on-click
              >
                <v-card flat>
                  <div>Species: {{ item.species }}</div>
                  <div>Nature of Incident: {{ item.incidentNature }}</div>
                  <v-btn
                      x-small
                      @click="editItem(item)"
                  >
                    See More
                  </v-btn>
                </v-card>
              </MglPopup>
            </MglMarker>
          </MglMap>
        </v-row>
      </v-col>
    </v-row>
    <ReportDetails></ReportDetails>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import enums from "@/helpers/enums";
import Mapbox from "mapbox-gl";
import { MglMap,
  MglMarker,
  MglPopup,
  MglNavigationControl,
  MglFullscreenControl,
} from "vue-mapbox";
import ReportDetails from "@/components/report/Details";
import "mapbox-gl/dist/mapbox-gl.css";

export default {
  data() {
    return {
      numberOfPins: 100,
      maxSelectDisplay: 1,
      query: "",
      date: null,
      dateField: false,
      options: {},
      selectedIncidentNatures: [],
      selectedSpecies: [],
      accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
      mapStyle: "mapbox://styles/mapbox/outdoors-v11",
      mapCenter: [-128.9110, 54.6057],
      zoom: 4.15,
      coordinates: [],
      markerColor: [],
      headers: [
        {
          text: "Species",
          sortable: false,
          value: Object.values(enums.species),
        },
        { text: "Amount", value: 1 },
      ],
    };
  },
  components: {
    MglMap,
    MglMarker,
    MglPopup,
    MglNavigationControl,
    MglFullscreenControl,
    ReportDetails,
  },
  mounted() {
    // Leaving this in just in case it's needed
  },
  async created() {
    this.options = this.searchOptions;
    this.query = this.searchQuery;

    if (this.options.itemsPerPage === -1) { //prevent -1 from being displayed in the box on reload
      this.options.itemsPerPage = "";
    }
    this.numberOfPins = this.options.itemsPerPage;
    if (
      this.options.itemsPerPage === null ||
      this.options.itemsPerPage === "" ||
      this.options.itemsPerPage === [] ||
      this.options.itemsPerPage === 0 ||
      this.options.itemsPerPage === undefined
    ) {
      //retrieve all pins when box is empty
      this.options.itemsPerPage = -1;
    }

    if (this.searchDate != null) this.date = this.searchDate;

    if (this.searchIncidentNature != null) {
      this.selectedIncidentNatures = this.searchIncidentNature;
    }

    if (this.searchSpecies != null) {
      this.selectedSpecies = this.searchSpecies;
    }
    await this.executeSearch();
  },
  computed: {
    ...mapGetters({
      loading: "dashboard/loading",
      searchOptions: "dashboard/searchOptions",
      searchQuery: "dashboard/searchQuery",
      searchDate: "reports/searchDate",
      searchIncidentNature: "dashboard/searchIncidentNature",
      searchSpecies: "dashboard/searchSpecies",
      items: "dashboard/items",
    }),
    dateRangeText () {
      if (this.date !== null) {
        if (this.date.length > 1) {
          return this.date.join(" ~ ");
        }
        else {
          return this.date;
        }
      }
      else {
        return this.date;
      }
    },
    enums() {
      return enums;
    },
  },
  watch: {
    async numberOfPins() {
      this.options.itemsPerPage = this.numberOfPins
      await this.executeSearch()
    },
    async date() {
      await this.executeSearch();
    },
    async selectedIncidentNatures() {
      await this.executeSearch()
    },
    async selectedSpecies() {
      await this.executeSearch()
    },
  },
  methods: {
    ...mapActions({
      open: "report/details/open",
      search: "dashboard/search"
    }),
    handleBackspace(e) {
      let charCode = (e.which) ? e.which : e.keyCode;
      // check for backspace (8) and delete (46) keys
      if ((charCode === 8 || charCode === 46) && this.numberOfPins.length === 1) {
        this.numberOfPins = null;
        e.preventDefault();
      }
      else {
        return true
      }
    },
    countSpecies(spec) {
      let count = 0;
      this.items.forEach((value) => {
        let isListed = false;
        if (value.species === spec) {
          count++;
        } else if (spec === "Other") {
          Object.values(enums.species).forEach((enumVal) => {
            if (value.species === enumVal) {
              isListed = true;
            }
          });
          if (!isListed) {
            count++;
          }
        }
      });
      return count;
    },
    countIncident(incident) {
      let count = 0;
      this.items.forEach((value) => {
        let isListed = false;
        if (value.incidentNature === incident) {
          count++;
        } else if (incident === "Other") {
          Object.values(enums.incidentType).forEach((enumVal) => {
            if (value.incidentNature === enumVal) {
              isListed = true;
            }
          });
          if (!isListed) {
            count++;
          }
        }
      });
      return count;
    },
    editItem(item) {
      this.open({ report: item });
    },
    async executeSearch() {
      await this.search({
        ...this.options,
        query: this.query,
        date: this.date,
        incidentNature: this.selectedIncidentNatures,
        species: this.selectedSpecies,
      });
      await this.reloadPins();
    },
    reloadPins() {
      this.coordinates = []
      this.markerColor = []
      for(let i = 0; i < this.items.length; i++) {
        this.coordinates.push({
          lat: this.items[i].lat,
          lon: this.items[i].lon,
        })
        if (this.items[i].incidentNature === "Ship Strike") {
          this.markerColor.push({
            color: "maroon",
          })
        }
        else if (this.items[i].incidentNature === "Entanglement") {
          this.markerColor.push({
            color: "olive",
          })
        }
        else {
          this.markerColor.push({
            color: "teal",
          })
        }
      }
      this.mapbox = Mapbox;
    }
  },
};
</script>

<style scoped>
.map-class {
  margin-top: 5vh;
  height: 67vh;
}
.filter-boxes {
  margin-top: -10vh;
}
.filter-boxes-2 {
  margin-top: -3vh;
}
</style>
