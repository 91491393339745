const testStatus = {
  NEGATIVE: "Negative",
  POSITIVE: "Positive",
  NONE: "None",
  INCONCLUSIVE: "Inconclusive",
};

/**
 *  Appointment statuses only exist in the frontend and are generated from the report status along with other
 *  appointment fields
 */
const appointmentStatus = {
  NO_PATIENT: "No Patient",
  NO_RESULT: "No Result",
  RESULT_ASSIGNED: "Result Assigned",
  EMAIL_SENT: "Email Sent",
  REPORT_OPENED: "Report Opened",
  FEEDBACK_SENT: "Feedback Sent",
};

/**
 *  Report statuses exist in the database and therefore must match the corresponding enum type in the backend.
 *  These reflect the status of the report itself.
 *
 *  A reasonable refactor would be to consolidate these two into a single status field that does live in the database.
 *  That would eliminate a lot of confusing logic in this area.
 */
const reportStatus = {
  UNSENT: "Unsent",
  LOCKED: "Locked",
  UNLOCKED: "Unlocked",
  VIEWED: "Viewed",
  FEEDBACK_SENT: "Feedback",
};

const emailDeliveryStatus = {
  UNSENT: "Unsent",
  EMAIL_PENDING: "Pending",
  EMAIL_DELIVERED: "Delivered",
  EMAIL_FAILED: "Failed",
  REPORT_OPENED: "Opened",
  FEEDBACK_SENT: "Feedback",
};

export default {
  testStatus: testStatus,
  testStatuses: [
    testStatus.NEGATIVE,
    testStatus.POSITIVE,
    testStatus.NONE,
    testStatus.INCONCLUSIVE,
  ],
  appointmentStatus: appointmentStatus,
  appointmentStatuses: [
    appointmentStatus.NO_PATIENT,
    appointmentStatus.NO_RESULT,
    appointmentStatus.RESULT_ASSIGNED,
    appointmentStatus.EMAIL_SENT,
    appointmentStatus.REPORT_OPENED,
    appointmentStatus.FEEDBACK_SENT,
  ],
  reportStatus: reportStatus,
  reportStatuses: [
    reportStatus.UNSENT,
    reportStatus.LOCKED,
    reportStatus.UNLOCKED,
    reportStatus.VIEWED,
    reportStatus.FEEDBACK_SENT,
  ],
  selectableReportStatuses: [
    reportStatus.UNSENT,
    "Sent", // "Sent" status is special and does not exist in the db, it is replaced with UNLOCKED upon status change
    reportStatus.VIEWED,
    reportStatus.FEEDBACK_SENT,
  ],
  emailDeliveryStatus: emailDeliveryStatus,
  emailDeliveryStatuses: [
    emailDeliveryStatus.UNSENT,
    emailDeliveryStatus.EMAIL_PENDING,
    emailDeliveryStatus.EMAIL_FAILED,
    emailDeliveryStatus.EMAIL_DELIVERED,
    emailDeliveryStatus.REPORT_OPENED,
    emailDeliveryStatus.FEEDBACK_SENT,
  ],
  emailDeliveryStatusColours: {
    Unsent: "yellow",
    Pending: "#91e886",
    Delivered: "green",
    Failed: "red",
    Opened: "#156c06",
    Feedback: "orange",
  },
  species: [
    "Humpback Whale",
    "Fin Whale",
    "Orca",
    "Steller Sea Lion",
    "Harbour Seal",
    "Elephant Seal",
    "Dalls Porpoise",
    "Pacific White Sided Dolphin",
    "Whale of Unknown Species",
    "Other",
  ],
  incidentType: ["Entanglement", "Ship Strike", "Stranding", "Unknown", "Other"],
  injuryLocation: [
    "Jaws",
    "Flippers",
    "Dorsal Body (the animals back)",
    "Ventral Body (the animals stomach)",
    "Fluke/Tail",
    "Other",
  ],
  travDirection: [
    "North",
    "North-East",
    "East",
    "South-East",
    "South",
    "South-West",
    "West",
    "North-West",
    "Unknown/Not Travelling",
  ],
};
