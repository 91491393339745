<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="headline custom-card-header">
          Delete Confirmation
        </v-card-title>
        <br />

        <v-card-text>
          <h4>Are you sure you want to delete {{ name }}?</h4>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn @click.native="close">
            <v-icon>mdi-close</v-icon>
            No
          </v-btn>
          <v-btn @click.native="() => deleteUser(id)" color="primary">
            <v-icon>mdi-check</v-icon>
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["id", "name"],

  methods: {
    ...mapActions({
      close: "delete/close",
      open: "delete/open",
      delete_user: "users/deleteUser",
    }),

    deleteUser() {
      this.delete_user(this.id);
      this.close();
    },
  },
  computed: {
    ...mapGetters({
      dialog: "delete/dialog",
    }),
  },
};
</script>

<style scoped></style>
