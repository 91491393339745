import axios from "axios";

export default {
  /**
   * Login to the API, using the OAuth2 login form type.
   *
   * @param email
   * @param password
   * @returns {Promise<{access_token, token_type}>}
   */
  async login({ email, password }) {
    const data = new URLSearchParams();
    data.append("grant_type", "password");
    data.append("username", email);
    data.append("password", password);

    try {
      const response = await axios.post("/token", data);
      return response.data;
    } catch (e) {
      return { access_token: null, token_type: "access_denied" };
    }
  },
};
