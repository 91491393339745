<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="70vw" persistent scrollable>
      <v-card>
        <v-card-title class="headline custom-card-header">
          Send Email {{report.reportNumber}}
          <v-spacer></v-spacer>
          <v-btn color="white" icon @click="close(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <br />

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
                class="pb-2"
                name="subject"
                v-model="subject"
                label="Subject"
                required
                :rules="rules"
            ></v-text-field>
            <v-textarea
                name="custom_message"
                label="Custom Message"
                outlined
                v-model="customMessage"
            ></v-textarea>
            <h4>Please enter emails separated by a comma</h4>
            <v-row>
              <v-col cols="8">
                <v-text-field
                    name="emails"
                    v-model="emails"
                    label="Emails"
                    required
                    :rules="rules"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                    name="default_emails"
                    label="Use default emails?"
                    v-model="useDefaultEmails"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn @click.native="close(false)">
            <v-icon>mdi-cancel</v-icon>
            Cancel
          </v-btn>
          <v-spacer/>
          <v-btn @click.native="togglePreviewDialog(true)">
            <v-icon>mdi-email-search</v-icon>
            Preview Email
          </v-btn>
          <v-spacer/>
          <v-btn @click.native="() => submit()" color="primary" :disabled="saving">
            <v-icon>mdi-email</v-icon>
            <span v-show="!saving">Send Email</span>
            <pulse-loader :loading="saving"></pulse-loader>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <email-preview
        :dialog="previewDialog"
        :custom-message="customMessage"
        :subject="subject"
        :emails="emails"
        :action="submit"
        :close="togglePreviewDialog"
    ></email-preview>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader";
import EmailPreview from "@/components/EmailPreview";

export default {
  props: ["dialog", "close"],

  components: {
    PulseLoader,
    EmailPreview,
  },

  data() {
    return {
      subject: null,
      customMessage: null,
      emails: null,
      previewDialog: false,
      mailList: [],
      rules: [(v) => !!v || "This field is required!"],
      valid: true,
      useDefaultEmails: false,
    }
  },
  computed: {
    ...mapGetters({
      saving: "report/details/emailSaving",
      report: "report/details/report",
    }),
  },
  watch: {
    dialog() {
      this.subject = null;
      this.emails = null;
      this.customMessage = null;
    },
    useDefaultEmails() {
      if (this.useDefaultEmails) {
        this.emails = "grace@bcwhales.org,janie@bcwhales.org";
      }
      else {
        this.emails = "";
      }
    },
  },
  methods: {
    ...mapActions({
      sendEmail: "report/details/sendEmail",
      previewEmail: "report/details/previewEmail",
    }),
    async submit() {
      await this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      this.mailList = this.emails ? this.emails.split(",") : []
      // remove whitespace from strings, if any
      for (let i = 0; i < this.mailList.length; i++) {
        this.mailList[i].trim();
      }
      await this.sendEmail({
        reportId: this.report.id,
        emailRequest: {
          customMessage: this.customMessage,
          subject: this.subject,
          mailList: this.mailList,
        }
      });
      this.close(false);
    },
    async togglePreviewDialog(dialog) {
      await this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      if (dialog) {
        this.mailList = this.emails.split(",")
        // remove whitespace from strings, if any
        for (const address of this.mailList) {
          address.trim();
        }

        await this.previewEmail({
          reportId: this.report.id,
          emailRequest: {
            customMessage: this.customMessage,
            subject: this.subject,
            mailList: this.mailList,
          }
        });
      }
      this.previewDialog = dialog;
    },
  },
};
</script>

<style scoped></style>
