import api from "@/services/api/users";

const state = {
  isLoading: false,
  items: [],
  roles: [],
  searchQuery: "",
  searchOptions: {
    page: 1,
    itemsPerPage: 10,
    sortBy: ["email"],
    sortDesc: [false],
    multiSort: false,
  },
  total: 0,
  userDetails: [],
};

const getters = {
  loading: (state) => state.isLoading,
  items: (state) => state.items,
  searchQuery: (state) => state.searchQuery,
  searchOptions: (state) => state.searchOptions,
  total: (state) => state.total,
  roles: (state) => state.roles,
  userDetails: (state) => state.userDetails,
};

const actions = {
  async search({ commit }, { query, page, itemsPerPage, sortBy, sortDesc }) {
    commit("loading", { isLoading: true });
    const result = await api.search({
      query,
      page,
      itemsPerPage,
      sortBy,
      sortDesc,
    });
    commit("loaded", {
      items: result.data,
      searchQuery: query,
      searchOptions: { page, itemsPerPage, sortBy, sortDesc },
      total: result.total,
    });
    commit("loading", { isLoading: false });
  },
  async reload({ dispatch, state }) {
    dispatch("search", {
      query: state.searchQuery,
      ...state.searchOptions,
    });
  },
  async loadRoles({ commit }) {
    commit("loading", { isLoading: true });

    const result = await api.loadRoles();
    commit("loadedRoles", result.data);

    commit("loading", { isLoading: false });
  },
  async deleteUser({ dispatch }, userId) {
    await api.delete(userId);
    dispatch("reload");
  },
  async getCurrentUser({ commit }) {
    const result = await api.getCurrentUser();
    commit("getUserDetails", result.data);
  },
};

const mutations = {
  loading(state, { isLoading }) {
    state.isLoading = isLoading;
  },
  loaded(state, { items, searchQuery, searchOptions, total }) {
    state.searchQuery = searchQuery;
    state.searchOptions = { ...searchOptions };
    state.total = total;
    state.items = items.map((item) => ({
      ...item,
      roleName: item.role.name,
    }));
  },
  loadedRoles(state, roles) {
    state.roles = roles;
  },
  getUserDetails(state, userDetails) {
    state.userDetails = userDetails;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
