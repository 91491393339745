<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h4 class="text-h4">
          <v-icon x-large class="pb-2">mdi-shield-account</v-icon>
          Users
        </h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="query"
          prepend-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-col>
      <v-col class="text-right">
        <v-btn color="primary" class="mt-3" @click="addItem">Add User</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-sheet elevation="2" class="pa-md-2">
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :options.sync="options"
            :server-items-length="total"
          >


            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                      v-on="on"
                      :disabled="userDialog"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit User</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                      small
                      @click="deleteItem(item)"
                      v-on="on"
                      :disabled="deleteDialog"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete User</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
    <user-details></user-details>
    <delete :id="selectedItem.id" :name="selectedItem.name"></delete>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "lodash";
import UserDetails from "@/components/user/Details";
import Delete from "@/components/Delete";

export default {
  components: {
    UserDetails,
    Delete,
  },
  data() {
    return {
      query: "",
      options: {},
      selectedItem: {},
      headers: [
        { text: "First Name", sortable: true, value: "firstName" },
        { text: "Last name", sortable: true, value: "lastName" },
        { text: "Email", sortable: true, value: "email" },
        { text: "Role", sortable: true, value: "roleName" },
        {
          text: "Actions",
          sortable: false,
          value: "actions",
          align: "center",
          width: "10%",
        },
      ],
    };
  },
  mounted() {},
  created() {
    this.options = this.searchOptions;
    this.query = this.searchQuery;
    this.debouncedSearch = debounce(this.executeSearch, 250);
    this.loadRoles();
  },
  computed: {
    ...mapGetters({
      loading: "users/loading",
      items: "users/items",
      searchOptions: "users/searchOptions",
      searchQuery: "users/searchQuery",
      total: "users/total",
      userDialog: "user/details/dialog",
      deleteDialog: "delete/dialog",
    }),
  },
  watch: {
    query() {
      this.debouncedSearch();
      this.options.page = 1;
    },
    options() {
      this.executeSearch();
    },
  },
  methods: {
    ...mapActions({
      search: "users/search",
      open: "user/details/open",
      loadRoles: "users/loadRoles",
      openDelete: "delete/open",
    }),
    executeSearch() {
      this.search({
        ...this.options,
        query: this.query,
      });
    },
    addItem() {
      this.open(undefined);
    },
    editItem(item) {
      this.open(item);
    },

    deleteItem(item) {
      this.selectedItem = item;
      this.openDelete();
    },
  },
};
</script>
