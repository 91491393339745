import router from "@/plugins/router";
import userApi from "@/services/api/users";

const state = {
  user: null,
  responseMessage: null,
};

const getters = {
  user: (state) => state.user,
  responseMessage: (state) => state.responseMessage
};

const actions = {
  async setUser({ commit }, { user }) {
    commit("setUser", user);
  },
  async resetPassword({ commit }, { passToken, password }) {
    try {
      const result = await userApi.reset(passToken, password);
      if (result.status > 400) {
        throw result.status;
      }
      commit("setUser", result.data);
      return result;
    } catch (e) {
      commit("setUser", null);
      console.log(e);
      throw e;
    }
  },
  async sendPasswordEmail({ commit }, { email }) {
    try {
      await userApi.passwordEmail(email);
    } catch (e) {
      commit("setUser", null);
      console.log(e);
      throw e;
    }
  },
  async accountManagementEmail({ commit }, { email, name, message }) {
    try {
      const response = await userApi.accountManagementEmail(email, name, message);
      if (response.data.isSuccess === false) {
        commit("setResponseMessage", response.data.message);
      }
      else {
        commit("setResponseMessage", "");
      }
    } catch (e) {
      commit("setResponseMessage", e.message);
      console.log(e);
      throw e;
    }
  },
  async setResponseMessage({ commit }, { message }) {
    commit("setResponseMessage", message)
  },
  async finish() {
    await router.push({ name: "login" });
  },
};

const mutations = {
  setUser(state, { user }) {
    state.user = user;
  },
  setResponseMessage(state, responseMessage) {
    state.responseMessage = responseMessage;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
