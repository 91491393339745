var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('h4',{staticClass:"text-h4"},[_c('v-icon',{staticClass:"pb-2",attrs:{"x-large":""}},[_vm._v("mdi-calendar-check")]),_vm._v(" Reports ")],1)]),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mt-5",attrs:{"color":"primary"},on:{"click":_vm.createReport}},[_vm._v(" Create Report ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"pr-6"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date Filter","prepend-icon":"mdi-calendar","readonly":"","clearable":""},on:{"click:clear":function($event){_vm.date = null}},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateField),callback:function ($$v) {_vm.dateField=$$v},expression:"dateField"}},[_c('v-date-picker',{attrs:{"range":"","show-adjacent-months":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.enums.incidentType,"prepend-icon":"mdi-alert-circle","label":"Incident Nature","name":"incident_nature","multiple":"","clearable":""},on:{"keyup":_vm.executeSearch},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < _vm.maxSelectDisplay)?_c('span',[_vm._v(_vm._s(item)+"  ")]):_vm._e(),(index === _vm.maxSelectDisplay)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.selectedIncidentNatures.length - _vm.maxSelectDisplay)+" more)")]):_vm._e()]}}]),model:{value:(_vm.selectedIncidentNatures),callback:function ($$v) {_vm.selectedIncidentNatures=$$v},expression:"selectedIncidentNatures"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.enums.species,"label":"Species","name":"species","multiple":"","clearable":""},on:{"keyup":_vm.executeSearch},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < _vm.maxSelectDisplay)?_c('span',[_vm._v(_vm._s(item)+"  ")]):_vm._e(),(index === _vm.maxSelectDisplay)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.selectedSpecies.length - _vm.maxSelectDisplay)+" more)")]):_vm._e()]}},{key:"prepend",fn:function(){return [_c('humpback-icon')]},proxy:true}]),model:{value:(_vm.selectedSpecies),callback:function ($$v) {_vm.selectedSpecies=$$v},expression:"selectedSpecies"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-sheet',{staticClass:"pa-md-2",attrs:{"elevation":"2"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.total},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","disabled":_vm.reportDialog},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("View Report")])])]}}])})],1),_c('v-btn',{staticClass:"mt-5",attrs:{"color":"primary"},on:{"click":_vm.exportData}},[_vm._v(" Export as CSV ")])],1)],1),_c('report-details')],1)}
var staticRenderFns = []

export { render, staticRenderFns }