import router from "@/plugins/router";
import api from "@/services/api/auth";
import userApi from "@/services/api/users";

const state = {
  message: { text: null, type: "error" },
  user: null,
  token: null,
  isLoading: false,
  username: "",
};

const getters = {
  loading: (state) => state.isLoading,
  message: (state) => state.message,
  user: (state) => state.user,
  token: (state) => state.token,
  isTokenSet: (state) => {
    return state.token != null && state.token !== "";
  },
  role: (state) => (state.user && state.user.role ? state.user.role : null),
  username: (state) => (state.username ? state.username : ""),
};

const actions = {
  async reset({ commit }) {
    commit("message", { text: null });
  },
  async login({ commit }, { email, password }) {
    commit("message", { text: null });
    commit("loading", { isLoading: true });

    const result = await api.login({ email, password });

    if (!result.access_token) {
      commit("message", {
        text: "Network Error. Please try again.",
        type: "error",
      });
      commit("loading", { isLoading: false });
    } else if (result.access_token === "fail") {
      commit("message", {
        text: "Invalid credentials, login failed.",
        type: "error",
      });
      commit("loading", { isLoading: false });
    } else {
      const payload = {
        user: { email: email, role: result.role },
        token: result.access_token,
      };

      commit("login", payload);
      commit("loading", { isLoading: false });

      const userResponse = await userApi.getCurrentUser();
      commit("updateUsername", userResponse.data);

      if (result.role === "admin") {
        await router.push({ name: "dashboard" });
        return;
      }
      await router.push({ name: "reports" });
    }
  },
  async logout({ commit, dispatch }) {
    dispatch("report/details/close", {}, { root: true });
    dispatch("user/details/close", {}, { root: true });

    commit("logout");
    await router.push({ name: "login" });
  },
  async clearToken({ commit }) {
    commit("logout");
  },
};

const mutations = {
  loading(state, { isLoading }) {
    state.isLoading = isLoading;
  },
  message(state, { text, type }) {
    state.message = { text };
    if (type) {
      state.message.type = type;
    }
  },
  login(state, { user, token }) {
    state.user = user;
    state.token = token;
  },
  logout(state) {
    state.user = null;
    state.token = null;
  },
  updateUsername(state, currentUser) {
    state.username = `${currentUser.firstName} ${currentUser.lastName}`;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
