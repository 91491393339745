import api from "@/services/api/reports";
import utils from "@/helpers/utils";

function formatReport(report) {
  const formattedReport = report;
  formattedReport.reportDate = utils.formatIsoDateTime(report.reportDate);
  formattedReport.sightingTime = utils.formatIsoDateTime(report.sightingTime);
  formattedReport.fullName = report.firstName + " " + report.lastName;
  return formattedReport;
}

const state = {
  exporting: false,
  exportMessage: "",
  isLoading: false,
  items: [],
  searchQuery: "",
  searchDate: null,
  searchIncidentNature: [],
  searchSpecies: [],
  searchOptions: {
    page: 1,
    itemsPerPage: 10,
    sortBy: ["reportNumber"],
    sortDesc: [false],
    multiSort: false,
  },
  total: 0,
};

const getters = {
  exporting: (state) => state.exporting,
  exportMessage: (state) => state.exportMessage,
  loading: (state) => state.isLoading,
  items: (state) => state.items,
  searchQuery: (state) => state.searchQuery,
  searchDate: (state) => state.searchDate,
  searchIncidentNature: (state) => state.searchIncidentNature,
  searchSpecies: (state) => state.searchSpecies,
  searchOptions: (state) => state.searchOptions,
  total: (state) => state.total,
};

const actions = {
  async search(
    { commit },
    { query, date, incidentNature, species, page, itemsPerPage, sortBy, sortDesc }
  ) {
    commit("loading", { isLoading: true });
    let originalQuery = query;
    let startDate;
    let endDate;

    if (date != null && date.length === 1) query = query + " " + date;
    else if (date != null && date.length > 1) {
      // correctly assign start date to lower value and end date to higher value
      if (date[0] > date[1]) {
        startDate = date[1];
        endDate = date[0];
      }
      else if (date[0] < date[1]) {
        startDate = date[0];
        endDate = date[1];
      }
    }

    const result = await api.search({
      query,
      startDate,
      endDate,
      incidentNature,
      species,
      page,
      itemsPerPage,
      sortBy,
      sortDesc,
    });
    commit("loaded", {
      items: result.data,
      searchQuery: originalQuery,
      searchDate: date,
      searchIncidentNature: incidentNature,
      searchSpecies: species,
      searchOptions: { page, itemsPerPage, sortBy, sortDesc },
      total: result.total,
    });
    commit("loading", { isLoading: false });
  },
  async reload({ dispatch, state }) {
    dispatch("search", {
      query: state.searchQuery,
      date: state.searchDate,
      incidentNature: state.searchIncidentNature,
      species: state.searchSpecies,
      ...state.searchOptions,
    });
  },
  async exportCsv({ commit }) {
    commit("exporting", true)
    console.log("exporting")
    try {
      await api.exportCsv();
      console.log("got here")
    } catch (e) {
      commit("setMessage", "Cannot export CSV");
    }
    commit("exporting", false)
  },
};

const mutations = {
  loading(state, { isLoading }) {
    state.isLoading = isLoading;
  },
  loaded(state, { items, searchQuery, searchDate, searchIncidentNature, searchSpecies, searchOptions, total }) {
    state.searchQuery = searchQuery;
    state.searchDate = searchDate;
    state.searchIncidentNature = searchIncidentNature;
    state.searchSpecies = searchSpecies;
    state.searchOptions = { ...searchOptions };
    state.total = total;
    state.items = items.map((i) => formatReport(i));
  },
  setMessage(state, message) {
    state.exportMessage = message;
  },
  exporting(state, exporting) {
    state.exporting = exporting;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

