<template>
  <v-app>
    <v-app-bar v-if="isTokenSet" color="primary" clipped-left app>
      <v-app-bar-title class="text-no-wrap">
        <div class="no-truncate">
          <humpback-logo class="appbar-vertical-align"></humpback-logo>
          <span class="pl-5 text-h6 white--text site-title appbar-vertical-align">BCWhales Incident Report Portal</span>
        </div>
      </v-app-bar-title>
      <v-spacer />
      <div class = "text pr-4">
        {{ username }}
      </div>
      <v-btn @click="logout">
        Logout
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-if="isTokenSet" permanent clipped app>
      <v-list dense nav class="mt-4">
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="ma-4">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import HumpbackLogo from "@/components/svgs/HumpbackLogo"

export default {
  methods: {
    ...mapActions({
      logout: "auth/logout",
      getCurrentUser: "users/getCurrentUser",
      clearToken: "auth/clearToken",
    }),
  },
  components: {
    HumpbackLogo
  },
  computed: {
    ...mapGetters({
      isTokenSet: "auth/isTokenSet",
      role: "auth/role",
      userDetails: "users/userDetails",
      username: "auth/username",
      token: "auth/token",
    }),
    items() {
      if (this.role && this.role === "admin") {
        // Logged in with what is apparently an admin role so show the admin items which should work for this user
        return this.adminItems;
      }
      // Otherwise, just show the user items - hide the admin items as they will be unusable for this user
      return this.staffItems;
    },
  },
  data: () => {
    const baseItems = [
      {
        title: "Reports",
        icon: "mdi-calendar-check",
        path: "/reports",
      },
    ];
    const adminItems = baseItems.concat([
      { title: "Dashboard", icon: "mdi-view-dashboard-variant", path: "/dashboard"},
      { title: "Users", icon: "mdi-shield-account", path: "/users" },
    ])
    return {
      user: {},
      adminItems:adminItems,
      staffItems: baseItems,
    };
  },
  created() {
    this.getCurrentUser()
  },
};
</script>

<style>
.no-truncate {
  overflow: visible;
  width: 365px;
}
.site-title {
  overflow: visible;
  display: inline-block;
}
.appbar-vertical-align {
  vertical-align: middle;
}
.text {
  color: white;
  font-size: 17px;
  text-align: left;
}
</style>
