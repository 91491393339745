import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#364563',
        secondary: '#b9b9b9',
        neutral: '#979797',
        accent: '#465593',
        error: "#FF5252" /* light red*/,
        info: "#2196F3" /* blue*/,
        success: "#4CAF50" /* green */,
        warning: "#FFC107" /* yellow */,
      },
    },
  },
});
