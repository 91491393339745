<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title class="headline custom-card-header"
        >{{ title }}
          <v-spacer></v-spacer>
          <v-btn color="white" icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                      name="first_name"
                      v-model="user.firstName"
                      label="First Name"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      name="last_name"
                      v-model="user.lastName"
                      label="Last Name"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      name="email"
                      v-model="user.email"
                      label="Email"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-select
                      v-model="selectedRoleId"
                      :items="roles"
                      item-text="name"
                      item-value="id"
                      outlined
                      label="Role"
                      name="role"
                  >
                    <scale-loader :loading="roleLoading"></scale-loader>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="9">
                  <v-text-field
                      name="password"
                      type="password"
                      v-model="password"
                      label="Password"
                      :required="changePassword"
                      :rules="passwordRules"
                      :disabled="!changePassword"
                      v-if="changePassword"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-checkbox
                      name="change_password"
                      label="Change Password?"
                      v-model="changePassword"
                      v-if="user && user.id"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <b class="red--text"> {{ message }}</b>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click.native="close">
            <v-icon>mdi-close</v-icon>
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn color="primary" @click.native="save">
            <v-icon>mdi-check</v-icon>
            <span v-show="!saving">{{ button }}</span>
            <pulse-loader :loading="saving"></pulse-loader>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader";
import ScaleLoader from "vue-spinner/src/ScaleLoader";

export default {
  components: {
    PulseLoader,
    ScaleLoader,
  },

  data() {
    return {
      selectedRoleId: null,
      changePassword: null,
      password: null,
      valid: true,
      passwordRules: [(v) => (!!v && v.length >= 6) || "Password must be at least 6 characters!"],
    };
  },
  watch: {
    user(user) {
      this.selectedRoleId = user ? user.roleId : null;
      this.setMessage("");
      this.password = user ? user.password : null;
      this.changePassword = !(this.user && this.user.id);
    },
  },
  computed: {
    ...mapGetters({
      saving: "user/details/saving",
      saved: "user/details/saved",
      user: "user/details/user",
      dialog: "user/details/dialog",
      button: "user/details/button",
      title: "user/details/title",
      roles: "users/roles",
      message: "user/details/message",
      roleLoading: "users/loading"
    }),
  },
  methods: {
    ...mapActions({
      update: "user/details/update",
      create: "user/details/create",
      close: "user/details/close",
      setMessage: "user/details/setMessage",
    }),
    async save() {
      await this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      if (this.changePassword) {
        this.user.password = this.password;
      }
      else {
        this.user.password = null;
      }
      if (!this.user.firstName) {
        this.setMessage("The user must have a first name!");
        return;
      }
      if (!this.user.lastName) {
        this.setMessage("The user must have a last name!");
        return;
      }
      if (this.changePassword && !this.user.password) {
        this.setMessage("The user must have a password!");
        return;
      }
      if (!this.user.email) {
        this.setMessage("The user must have a email!");
        return;
      }
      if (!this.selectedRoleId) {
        this.setMessage("The user must have a role assigned!");
        return;
      }

      this.user.roleId = this.selectedRoleId;
      if (this.user && this.user.id) {
        this.update(this.user);
      } else {
        this.create(this.user);
      }
    },
  },
};
</script>

<style>
.custom-card-header {
  background-color: #364563;
  color: white;
}
</style>
